import { Component, OnInit, Input } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'spinner',
    templateUrl: 'spinner.component.html',
    styleUrls: ['./spinner.component.css']
})

export class SpinnerComponent implements OnInit {
    @Input() showLoading: boolean;

    constructor() { }

    ngOnInit() { }
}