import { Component, OnInit, ContentChildren, AfterContentInit, QueryList, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PjeTabComponent } from './pje-tab/pje-tab.component';

@Component({
	selector: 'pje-tabs',
	templateUrl: './pje-tabs.component.html',
	styleUrls: ['./pje-tabs.component.scss']
})
export class PjeTabsComponent implements AfterContentInit {

	constructor() { }


	@ContentChildren(PjeTabComponent) tabs: QueryList<PjeTabComponent>;

	@Input("styleClass")
	public styleClass : string = "";


	ngAfterContentInit() {
		let activeTabs = this.tabs.filter((tab) => tab.active);
		if (activeTabs.length === 0) {
			this.selectTab(this.tabs.first);
		}
	}

	selectTab(tab: PjeTabComponent) {
		this.tabs.toArray().forEach(tab => tab.active = false);
		tab.active = true;
	}

}
