import { Injectable } from '@angular/core';

@Injectable()
export class LoggerService {


    constructor() { }

    warn(message: String){
        console.warn("[WARN] " + message);        
    }

    info(message: String){
        console.info("[INFO] " + message);        
    }

    error(message: String){
        console.error("[ERROR] " + message);        
    }

    debug(message: String){
        console.debug("[DEBUG] " + message);        
    }

    fatal(message: String){
        console.exception("[FATAL] " + message);        
    }                
}