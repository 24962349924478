import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
	selector: '[pjeMascaraCpfCnpj]'
})
export class PjeMascaraCpfCnpjDirective {

	private el : HTMLInputElement;

	constructor(el: ElementRef, private ngModel: NgModel) {
		this.el = el.nativeElement;
	}

	@HostListener('input', ['$event.target.value'])
	onKeyUp(value){
		if (value.replace( /\D/g , "").length > 13){
			this.inserirMascara(value+"\n");
		}
		else {
			this.inserirMascara(value);
		}
		this.ngModel.update.emit(this.el.value);
	}	

	private inserirMascara(input : any) {
		let res = input;

		if (res.length > 14) {
			res = res.replace( /\D/g , "");
			res = res.replace( /^(\d{2})(\d)/ , "$1.$2");
			res = res.replace( /^(\d{2})\.(\d{3})(\d)/ , "$1.$2.$3");
			res = res.replace( /\.(\d{3})(\d)/ , ".$1/$2");
			res = res.replace( /(\d{4})(\d)/ , "$1-$2");
		}
		else {
			res = res.replace( /\D/g , "");
			res = res.replace( /(\d{3})(\d)/ , "$1.$2");
			res = res.replace( /(\d{3})(\d)/ , "$1.$2");
			res = res.replace( /(\d{3})(\d{1,2})$/ , "$1-$2");
		}
		this.el.value = res;
		
	}

}
