import { Component, OnInit, Input } from '@angular/core';
import { ProcessoService, UtilService } from "../../../core/services";
import { AppConfig } from 'app/app.config';
import { Prioridade } from 'app/model/prioridade-model';

@Component({
    selector: 'pje-ico-prioridades',
    templateUrl: './pje-ico-prioridades.component.html',
    styleUrls: ['./pje-ico-prioridades.component.scss']
})
export class PjeIcoPrioridadesComponent implements OnInit {

    @Input()
    idProcessoTrf: number;
   

    public prioridades : Prioridade[];

    constructor(private processoService: ProcessoService, private config: AppConfig) { }
    

    ngOnInit() {
        this.getPrioridadesDoProcesso(this.idProcessoTrf)

    }

    private getPrioridadesDoProcesso(idProcessoTrf) {
        this.processoService.getPrioridadeProcesso(idProcessoTrf).subscribe(
            (prioridades) => {
                this.prioridades = prioridades;
            }
        );
    }
    

}
