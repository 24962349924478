export const PJE_LEGACY = "/seam/resource/rest/pje-legacy";
export const CRIMINAL = "/criminal";
export const COMPETENCIA = "/competencias";
export const BACEN = "/bacen-service";
export const ECARTA = "/ecarta-service";
export const INSSJUD = "/inss-service/api/v1";
export const MNI_CLIENT = "/mni-client";
export const RENAJUD = "/renajud-service"
export const SESSAOJULGAMENTO = "/sessao-julgamento"
export const REQUISITORIO = "/requisitorio/api";
export const LEILAO = "/leilao-service";
export const AJG_SERVICE = "/ajg";
export const ANALYTICS = '/analytics';
export const API = '/api';
export const API_V1 = API + '/v1';
export const API_V2 = API + '/v2';
