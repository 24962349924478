import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'pje-card-parte',
	templateUrl: './pje-card-parte.component.html',
	styleUrls: ['./pje-card-parte.component.scss']
})
export class PjeCardParteComponent implements OnInit {

	@Input('tipoPolo')
	tipoPolo : string;

	@Input('parte')
	parte : any = null;

	@Input('selected')
	selected : boolean = false;

	ativo: boolean = false;
	passivo : boolean = false;
	terceiro : boolean = false;


	constructor() { }

	ngOnInit() {
		if(this.tipoPolo === 'A' ){
			this.ativo = true;
		} else if(this.tipoPolo === 'P' ){
			this.passivo = true;
		} else if(this.tipoPolo === 'T' ){
			this.terceiro = true;
		}
	}

}
