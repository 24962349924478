import { Injectable } from '@angular/core';
import { AppConfig } from 'app/app.config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { COMPETENCIA, PJE_LEGACY } from './rest-servers';
import { PjeResponse, Page, CompetenciaClasseAssunto } from 'app/model';
import { GenericService } from './generic.service';


@Injectable({
  providedIn: 'root'
})
export class CompetenciaClasseAssuntoService extends GenericService<any> {

  constructor(private httpClient: HttpClient, private config: AppConfig) {
    super(httpClient, config);
  }

  public pesquisarCompetencias(pagina: number, tamanhoPagina: number, simpleFilter: any): Observable<PjeResponse<Page<CompetenciaClasseAssunto>>> {
    let params = new HttpParams();
    params = pagina ? params.append('page', '' + pagina) : params;
    params = tamanhoPagina ? params.append('size', '' + tamanhoPagina) : params;
    params = simpleFilter ? params.append('simpleFilter', JSON.stringify(simpleFilter)) : params;

    return this.httpClient
      .get<PjeResponse<Page<CompetenciaClasseAssunto>>>(this.getLegacyUrl(PJE_LEGACY + COMPETENCIA + '/classes-assuntos'), { params: params })
      .pipe(catchError(this._serverError));
  }

  public atualizarCompetencias(listaCompetencias: any): Observable<any> {
    return this.httpClient.put<any>(this.getLegacyUrl(PJE_LEGACY + COMPETENCIA + '/classes-assuntos'), listaCompetencias)
      .pipe(catchError(this._serverError));

  }

  getLegacyUrl(nomeServico: string = ''): string {
    let url: string = '';

    if (nomeServico) {
      url = this.config.getUrlPjelegacy() + nomeServico;
    }

    return url;
  }
}