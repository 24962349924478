import { NgModule } from '@angular/core';

import { SimpleFilterPipe } from './simple-filter.pipe';
import { DateUTCPipe } from './date-utc.pipe';

@NgModule({
    exports: [
        SimpleFilterPipe, 
        DateUTCPipe
    ],
    declarations: [
        SimpleFilterPipe, 
        DateUTCPipe
    ],
})
export class PjePipesModule { }
