
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { AppConfig } from '../../app.config';

@Injectable()
export class GenericService<T> {

    private restPath: string = "";

    constructor(private _http: HttpClient, private _config: AppConfig) { 
    }

    _serverError(err: any) {
        console.error('sever error:', err);
        return observableThrowError(err || 'backend server error');
    }

    findAll(): Observable<T[]>{
        return this
            ._http
            .get<T[]>(this.getLegacyUrl(this.restPath)).pipe(
            catchError(this._serverError));    
    }

    findById(id: number): Observable<T>{
        return this
            ._http
            .post<T>(this.getLegacyUrl(this.restPath) + '/' + id, {}).pipe(
            catchError(this._serverError));
    }

    getLegacyUrl(nomeServico:string): string{
        let url: string = "";
        
        if(!nomeServico){
            nomeServico = "";
        } else {
            if(this._config.corsEnabled){
                url = this._config.getUrlPjelegacy() + nomeServico;
            } else {
                url = this._config.getUrlApiGateway() + "/" +this._config.pjeLegacyCloudAppName + this._config.getContextPathPjeLegacy() + nomeServico;
            }
        } 

        return url;
    }
    
    public get getRestPath() : string {
        return this._config.getUrlApiGateway();
    }

    
    public set setRestPath(restPath : string) {
        this.restPath = restPath;
    }
    
     /**
     * Tenta realizar a requisição get informada e se o retorno no subscribe for
     * nulo, tenta novamente a requisição depois de 1 segundo.
     * @param httpGet método que realiza uma requisição get usando o objeto do tipo HttpClient.
     * @param callback método que deve ser chamado se o retorno da requisição for bem sucedido.
     * @param param parâmetro opcional a ser passado como argumento para o método de requisição httpGet.
     * @param error parâmetro opcional que consiste no método a ser chamado em caso de falha na 
     * requisição (é chamado apenas na segunda tentativa).
     */
    wrapperGet(httpGet: any, callback: (resposta: any)=>any, param?: any, error?: ()=>any) {
        httpGet.apply(this, [param]).subscribe((retorno)=> {
            if (!retorno) {
                let $this = this;
                setTimeout(function () {
                    httpGet.apply($this).subscribe((retorno)=> { 
                        callback(retorno);
                    },
                    ()=> { if (error) { error.apply($this) }});
                }, 1000);
            }
            else {
                callback(retorno);
            }
        });
    }
    
}