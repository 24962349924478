import { Component, OnInit } from '@angular/core';
import { AppConfig } from './app.config';
import { AuthenticationService, NotificationMessageService } from './core/services';

// declare var formulario:any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	providers: [NotificationMessageService],
})
export class AppComponent implements OnInit {

	AUX_NG_CLIENT = { NG_CLIENT: true };

	constructor() {
	}

	ngOnInit() {
	
	}
}
