import { Injectable } from '@angular/core';

@Injectable()
export class DataSharedService {

  private _data: any;

  constructor() { }


  get data(): any {
    return this._data;
  }

  set data(value: any) {
    this._data = value;
  }
}
