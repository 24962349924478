import { Injectable } from '@angular/core';

@Injectable()
export class PjeOfficeService {

    constructor() { }

    executar(requisicao: any, onSucesso: any, onErro: any, onIndisponivel:any){
        let t = requisicao.tarefa;
        requisicao.tarefa = JSON.stringify(t);
        let r = JSON.stringify(requisicao);
        r = encodeURIComponent(r);
        let image = new Image();
        image.onload = (arg: any) => {
            if(image.width === 2){
                onErro();
            } else {
                onSucesso();
            }
        }
        image.onerror = onIndisponivel;
        image.src = "http://localhost:8800/pjeOffice/requisicao/?r=" + r + "&u=" + new Date().getTime();
    }

    verificarDisponibilidade(onDisponivel: any, onIndisponivel: any){
        let image = new Image();
        image.onload = onDisponivel;
        image.onerror = onIndisponivel;	
        image.src = "http://localhost:8800/pjeOffice/?&u=" + new Date().getTime();
    }

    parseToListaArquivos(docFields: string){
        let arquivos = [];
        let items = docFields.split(",");
        for (var i = 0; i < items.length; i++) {
            var itemFields = items[i].split("&");
            if (itemFields.length == 4) {		
                arquivos[i] = {
                    "id"   		: itemFields[0].substr(itemFields[0].indexOf("=") + 1),
                    "codIni" 	: itemFields[1].substr(itemFields[1].indexOf("=") + 1),
                    "hash" 		: itemFields[2].substr(itemFields[2].indexOf("=") + 1),
                    "isBin" 	: itemFields[3].substr(itemFields[3].indexOf("=") + 1)
                }
            } else {
                arquivos[i] = {
                    "hash" 		: itemFields[0].substr(itemFields[0].indexOf("=") + 1)
                }
            }
        }
        return arquivos;
    }
}