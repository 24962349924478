import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'app/app.config';
import { Demanda, ProtocoloDemanda } from 'app/inss/models/models';
import { Observable } from 'rxjs';
import { INSSJUD } from './rest-servers';
import { PjeResponse } from 'app/model';

@Injectable()
export class InssService extends GenericService<any>{

    constructor(private http: HttpClient, private config:AppConfig) {
        super(http, config);
    }

    postNovaDemanda(novaDemanda : Demanda) : Observable<PjeResponse<Demanda>>{
        if (novaDemanda === null || novaDemanda === undefined) {
            throw new Error('A nova demanda a ser cadastrada não foi informada corretamente.');
        }
        
        return this.http.post<PjeResponse<Demanda>>(this.config.getUrlApiGateway() + '/' + INSSJUD + '/demandas', novaDemanda);
    }

    putNovaDemanda(idDemanda: number, novaDemanda : Demanda) : Observable<PjeResponse<Demanda>>{
        if (novaDemanda === null || novaDemanda === undefined) {
            throw new Error('A nova demanda a ser cadastrada não foi informada corretamente.');
        }
        
        return this.http.put<PjeResponse<Demanda>>(this.config.getUrlApiGateway() + '/' + INSSJUD + '/demandas/' + idDemanda, novaDemanda);
    }    

    getDemandaPorIdentificador(identificador: string): Observable<PjeResponse<Demanda>>{
        return this.http.get<PjeResponse<Demanda>>(this.config.getUrlApiGateway() + '/' + INSSJUD + '/demandas/' + identificador);
    }

    deleteDemandaPorIdentificador(identificador: string): Observable<PjeResponse<Demanda>>{
        return this.http.delete<PjeResponse<Demanda>>(this.config.getUrlApiGateway() + '/' + INSSJUD + '/demandas/' + identificador);
    }

    publishDemandaPorIdentificador(identificador: string): Observable<PjeResponse<Demanda>>{
        return this.http.post<PjeResponse<Demanda>>(this.config.getUrlApiGateway() + '/' + INSSJUD + '/demandas:publicar/' + identificador, null);
    }
}