import {catchError, map} from 'rxjs/operators';
import { SentencaExtincaoPunibilidade } from './../../criminal/model/sentenca-extincao-punibilidade.model';
import { TipoProcessoEnum } from './../../criminal/model/tipo-processo.enum';
import { MotivoRetornoEvasao } from './../../criminal/model/motivo-retorno-evasao.model';
import { Decisao } from './../../criminal/model/decisao.model';
import { SuspensaoMedidaSocioeducativa } from './../../criminal/model/suspensao-medida-socioeducativa.model';
import { SentencaExtincaoMedidaSocioeducativa } from '../../criminal/model/sentenca-extincao-medida-socioeducativa.model';
import { Semiliberdade } from './../../criminal/model/semiliberdade.model';
import { Evasao } from './../../criminal/model/evasao.model';
import { SentencaInfracional } from './../../criminal/model/sentenca-infracional.model';
import { Internacao } from './../../criminal/model/internacao.model';
import { TipoInternacao } from './../../criminal/model/tipo-internacao.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, of, throwError } from 'rxjs'
import { GenericService } from './generic.service';
import { AppConfig } from '../../app.config';
import { CRIMINAL, API_V2 } from './rest-servers';
import { TipoPrisao, MotivoPrisao, Prisao, Soltura, Fuga } from '../../criminal/model';
import { MotivoSoltura } from '../../criminal/model/motivo-soltura.model';
import { TipoInformacaoCriminal } from '../../criminal/model/tipo-Informacao-criminal.model';
import { PendenciaCriminal, TipoEventoCriminal } from "../../criminal/model/pendencia-criminal";
import { EventoCriminal } from '../../criminal/model/evento-criminal.model';
import { Processo } from "../../model";
import { Estado } from "../../criminal/model/estado.model";
import { SuspensaoProcesso } from "../../criminal/model/suspensao-processo.model";
import { MotivoSuspensaoProcesso } from "../../criminal/model/motivo-suspensao-processo.model";
import { TipoRecurso } from "../../criminal/model/tipo-recurso.model";
import { DecisaoInstanciaSuperior } from "../../criminal/model/decisao-instancia-superior.model";
import { Regime } from '../../criminal/model/regime.model';
import { SentencaCondenatoria } from 'app/criminal/model/sentenca-condenatoria.model';
import { SentencaAbsolutoria } from 'app/criminal/model/sentenca-absolutoria.model';
import { Norma } from 'app/criminal/model/norma.model';
import { SentencaImpronuncia } from 'app/criminal/model/sentenca-impronuncia.model';
import { SentencaPronuncia } from 'app/criminal/model/sentenca-pronuncia.model';
import { DesclassificacaoProcesso } from 'app/criminal/model/desclassificacao-processo.model';
import { Citacao } from 'app/criminal/model/citacao.model';
import { RecebimentoAditamentoDenuncia } from 'app/criminal/model/recebimento-aditamento-denuncia.model';
import { EventoCriminalDTO } from 'app/criminal/model/evento-criminal-dto.model';
import { ArquivamentoIncidencia } from 'app/criminal/model/arquivamento-incidencia.model';
import { SentencaAbsolutoriaImpropria } from 'app/criminal/model/sentenca-absolutoria-impropria.model';
import { PresoOutroProcesso } from 'app/criminal/model/preso-outro-processo.model';
import { DesmembramentoIC } from 'app/criminal/model/desmembramento-ic.model';
import { UnidadePrisional } from 'app/criminal/model/unidade-prisional.model';
import { TransacaoPenal } from 'app/criminal/model/transacao-penal.model';
import { RevogacaoSuspensaoCondicionalProcesso } from 'app/criminal/model/revogacao-suspensao-condicional-processo.model';
import { AusenciaCondicoesAcao } from 'app/criminal/model/ausencia-condicoes-acao.model.';
import { DeclinioCompetencia } from 'app/criminal/model/declinio-competencia.model';
import { Recorrente } from 'app/criminal/model/recorrente.model';
import { OrgaoJulgador } from 'app/criminal/model/orgao.julgador.model';
import { TipoPenaExecucao } from 'app/criminal/model/tipo-pena-execucao.model';
import { MedidaSeguranca } from 'app/criminal/model/medida-seguranca.model';
import { HomologacaoComposicaoDanoCivil } from 'app/criminal/model/homologacao-composicao-dano-civil.model';
import { LitispendenciaCoisaJulgada } from 'app/criminal/model/litispendencia-coisa-julgada.model';
import { NulidadeProcesso } from 'app/criminal/model/nulidade-processo.model';
import { Trancamento } from 'app/criminal/model/trancamento.model';
import { SentencaAbsolvicaoSumaria } from 'app/criminal/model/sentenca-absolvicao-sumaria.model';

import { MedidaSocioeducativa } from 'app/criminal/model/medida-socioeducativa.model';
import { MotivoExtincaoMedidaSocioeducativa } from 'app/criminal/model/motivo-extincao-medida-socioeducativa.model';
import { MotivoInternacaoProvisoria } from 'app/criminal/model/motivo-internacao-provisoria.model';
import { Remissao } from 'app/criminal/model/remissao.model';
import { TipoRemissao } from 'app/criminal/model/tipo-remissao.model';


@Injectable()
export class CriminalService  extends GenericService<any>{

    constructor(private httpClient: HttpClient, private config:AppConfig) {
        super(httpClient, config);
    }
  
    getEstados() : Observable<Estado[]> {
        return this.httpClient.get<Estado[]>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/estados").pipe(catchError(this._serverError));
    }

    getNormas(): Observable<Norma[]> {
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/normas")
            .pipe(
                map((_normas: Norma[]) => {
                    let normas: Norma[] = null;
                    if (_normas) {
                        normas = [];
                        _normas.forEach((n) => normas.push(new Norma(n.id, n.numeroNorma, n.norma,
                            n.sigla, n.inicioVigencia, n.fimVigencia)));
                    }
                    return normas;
                }),
                catchError(this._serverError));
    }

    getTiposDispositivos() : Observable<any>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/tiposDispositivos").pipe(catchError(this._serverError));
    }

    getTiposDispositivoArtigo() : Observable<any>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/tiposDispositivos/artigo" ).pipe(catchError(this._serverError));
    }

    getDispositivos(idNorma : number, idTipoDispositivo : number) : Observable<any>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/dispositivos/" + idNorma + "/" + idTipoDispositivo).pipe(catchError(this._serverError));
    }

    getDispositivosByIdDispositivoPai(idDispositivoPai : number) : Observable<any>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/dispositivos/dispositivoPai/" + idDispositivoPai).pipe(catchError(this._serverError));
    }

    getDispositivosByTexto(idNorma : number, idTipoDispositivo : number, texto : string) : Observable<any>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/dispositivos/" + idNorma + "/" + idTipoDispositivo + "/" + texto).pipe(catchError(this._serverError));
    }

    getTiposPrisao() : Observable<TipoPrisao[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/tiposPrisao").pipe(catchError(this._serverError));
    }

    getMotivosPrisao() : Observable<MotivoPrisao[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/motivosPrisao").pipe(catchError(this._serverError));
    }

    getUnidadesPrisionais(): Observable<any>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/unidadesPrisionais').pipe(catchError(this._serverError));
    }

    getUnidadesPrisionaisPorSiglaUf(siglaUf : string): Observable<any>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/unidadesPrisionais/estados/' + siglaUf).pipe(catchError(this._serverError));
    }

    getMotivosSoltura() : Observable<MotivoSoltura[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/motivosSoltura").pipe(catchError(this._serverError));
    }

    getTiposInternacao(): Observable<TipoInternacao[]> {
        const httpParams = new HttpParams().set('simpleFilter', '{"ativo": true}');
        return this.httpClient.get<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL + API_V2 + '/tipos-internacao',
                {params: httpParams}).pipe(
            map((page) => page.result.content),
            catchError(this._serverError));
    }

    getTiposRemissao(): Observable<TipoRemissao[]> {
        const httpParams = new HttpParams().set('simpleFilter', '{"ativo": true}');
        return this.httpClient.get<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL + API_V2 + '/tipos-remissao',
                {params: httpParams}).pipe(
            map((page) => page.result.content),
            catchError(this._serverError));
    }

    getMedidasSocioeducativas(): Observable<MedidaSocioeducativa[]> {
        const httpParams = new HttpParams().set('simpleFilter', '{"ativo": true}');
        return this.httpClient.get<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL + API_V2 + '/medidas-socioeducativas',
                {params: httpParams}).pipe(
            map((page) => page.result.content),
            catchError(this._serverError));
    }

    getMotivosRetornoEvasao(): Observable<MotivoRetornoEvasao[]> {
        const httpParams = new HttpParams().set('simpleFilter', '{"ativo": true}');
        return this.httpClient.get<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL + API_V2 + '/motivos-retorno-evasao',
                {params: httpParams}).pipe(
            map((page) => page.result.content),
            catchError(this._serverError));
    }

    getMotivosExtincaoMedidaSocioeducativa(): Observable<MotivoExtincaoMedidaSocioeducativa[]> {
        const httpParams = new HttpParams().set('simpleFilter', '{"ativo": true}');
        return this.httpClient.get<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL + API_V2 + '/motivos-extincao-medida-socioeducativa',
                {params: httpParams}).pipe(
            map((page) => page.result.content),
            catchError(this._serverError));
    }

    getMotivosInternacaoProvisoria(): Observable<MotivoInternacaoProvisoria[]> {
        const httpParams = new HttpParams().set('simpleFilter', '{"ativo": true}');
        return this.httpClient.get<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL + API_V2 + '/motivos-internacao-provisoria',
                {params: httpParams}).pipe(
            map((page) => page.result.content),
            catchError(this._serverError));
    }

    getIncidenciasPenaisParte(idProcessoCriminal : number, idProcessoParteLegacy : number) : Observable<any[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/incidencias-penais/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy).pipe(catchError(this._serverError));
    }

    postIncidenciaPenalParte(idProcessoCriminal : number, idProcessoParteLegacy : number, dispositvo : any) : Observable<any[]>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/incidencias-penais/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, dispositvo).pipe(catchError(this._serverError));
    }

    deleteIncidenciaPenalParte(idProcessoCriminal : number, idProcessoParteLegacy : number, incidenciaPenal : any) : Observable<any>{
        return this.httpClient.post(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/incidencias-penais/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy + '/delete', incidenciaPenal);
    }

    getPrisoesParte(idProcessoCriminal : number, idProcessoParteLegacy : number) : Observable<Prisao[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/prisoes/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy).pipe(catchError(this._serverError));
    }

    postPrisaoParte(idProcessoCriminal : number, idProcessoParteLegacy : number, prisao : Prisao) : Observable<Prisao[]>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/prisoes/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, prisao).pipe(catchError(this._serverError));
    }

    postSuspensaoProcessoParte(idProcessoCriminal : number, idProcessoParteLegacy : number, suspensaoProcesso : SuspensaoProcesso) : Observable<SuspensaoProcesso[]>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/suspensoesProcesso/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, suspensaoProcesso).pipe(catchError(this._serverError));
    }

    postSentencaCondenatoria(idProcessoCriminal : number, idProcessoParteLegacy : number, sentencaCondenatoria : SentencaCondenatoria) : Observable<SentencaCondenatoria[]>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/sentencasCondenatorias/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, sentencaCondenatoria).pipe(catchError(this._serverError));
    }

    alterarPrisaoParte(idProcessoCriminal : number, idProcessoParteLegacy : number, prisao : Prisao) : Observable<Prisao[]>{
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/prisoes/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, prisao).pipe(catchError(this._serverError));
    }

    alterarSuspensaoProcessoParte(idProcessoCriminal : number, idProcessoParteLegacy : number, suspensaoProcesso : SuspensaoProcesso) : Observable<SuspensaoProcesso[]> {
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/suspensoesProcesso/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, suspensaoProcesso).pipe(catchError(this._serverError));
    }

    alterarSentencaCondenatoria(idProcessoCriminal : number, idProcessoParteLegacy : number, sentencaCondenatoria : SentencaCondenatoria) : Observable<SentencaCondenatoria[]> {
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/sentencasCondenatorias/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, sentencaCondenatoria).pipe(catchError(this._serverError));
    }

    deletePrisaoParte(idProcessoCriminal : number, idProcessoParteLegacy : number, prisao : Prisao) : Observable<any>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/prisoes/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy + '/delete', prisao);
    }

    getFugasParte(idProcessoCriminal : number, idProcessoParteLegacy : number) : Observable<Fuga[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/fugas/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy).pipe(catchError(this._serverError));
    }

    postFugaParte(idProcessoCriminal : number, idProcessoParteLegacy : number, fuga : Fuga) : Observable<Fuga[]>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/fugas/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, fuga).pipe(catchError(this._serverError));
    }

    alterarFugaParte(idProcessoCriminal : number, idProcessoParteLegacy : number, fuga : Fuga) : Observable<Fuga[]>{
      return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/fugas/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, fuga).pipe(catchError(this._serverError));
    }

    deleteFugaParte(idProcessoCriminal : number, idProcessoParteLegacy : number, fuga : Fuga) : Observable<any>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/fugas/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy + '/delete', fuga);
    }

    getSolturasParte(idProcessoCriminal : number, idProcessoParteLegacy : number) : Observable<Soltura[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/solturas/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy).pipe(catchError(this._serverError));
    }

    postSolturaParte(idProcessoCriminal : number, idProcessoParteLegacy : number, soltura : Soltura) : Observable<Soltura[]>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/solturas/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, soltura).pipe(catchError(this._serverError));
    }

    alterarSolturaParte(idProcessoCriminal : number, idProcessoParteLegacy : number, soltura : Soltura) : Observable<Soltura[]>{
      return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/solturas/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, soltura).pipe(catchError(this._serverError));
    }

    deleteSolturaParte(idProcessoCriminal : number, idProcessoParteLegacy : number, soltura : Soltura) : Observable<any>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/solturas/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy + '/delete', soltura);
    }

    getProcessoCriminal(numeroProcessoLegacy : string) : Observable<Processo>{
        return this.httpClient.get<Processo>(this.config.getUrlApiGateway() + '/' + CRIMINAL + API_V2 + '/processos/numero/' + numeroProcessoLegacy).pipe(catchError(this._serverError));
    }

    getTipoInformacaoCriminal(codigoMovimento : string) : Observable<TipoInformacaoCriminal[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/tiposInformacaoCriminal/evento/' + codigoMovimento).pipe(catchError(this._serverError));
    }

    salvarInformacaoCriminalParte(idProcesso: number, idProcessoParteLegacy: number, eventoCriminal: EventoCriminal) {
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/eventosCriminais/processos/' + idProcesso.toString() + '/partes/' + idProcessoParteLegacy.toString(), eventoCriminal).pipe(catchError(this._serverError));
    }

    alterarInformacaoCriminalParte(idProcesso: number, idProcessoParteLegacy: number, eventoCriminal: EventoCriminal) {
      return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/eventosCriminais/processos/' + idProcesso.toString() + '/partes/' + idProcessoParteLegacy.toString(), eventoCriminal).pipe(catchError(this._serverError));
    }

    getEventosCriminais(idProcesso: number, idProcessoParteLegacy: number) {
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/eventosCriminais/processos/' + idProcesso.toString() + '/partes/' + idProcessoParteLegacy.toString()).pipe(catchError(this._serverError));
    }

    getEventosCriminaisDTO(idProcesso: number, idProcessoParteLegacy: number): Observable<EventoCriminalDTO[]> {
        return this.httpClient.get<EventoCriminalDTO[]>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/eventosCriminaisDTO/processos/'
        + idProcesso.toString() + '/partes/' + idProcessoParteLegacy.toString()).pipe(catchError(this._serverError));
    }

    getIncidencias(idProcesso: number, idProcessoParteLegacy: number) {
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/processos/' + idProcesso.toString() + '/partes/' + idProcessoParteLegacy.toString() + '/incidencias').pipe(catchError(this._serverError));
    }

  	getPendencias(idProcesso: number, movimentos : any) : Observable<PendenciaCriminal[]> {
    	return this.httpClient.post<PendenciaCriminal[]>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/eventosCriminais/processos/'+ idProcesso.toString() + '/pendencias',movimentos).pipe(catchError(this._serverError));
  	}


    getTiposEventosCriminais(): Observable<TipoEventoCriminal[]> {
        return this.httpClient.get<TipoEventoCriminal[]>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/tiposEventosCriminais').pipe(catchError(this._serverError));
    }

    getTiposEventosCriminaisAtivosPorProcesso(idProcessoCriminal: number): Observable<TipoEventoCriminal[]> {
        const httpParams = new HttpParams().set('ativo', 'true');
        return this.httpClient.get<TipoEventoCriminal[]>(this.config.getUrlApiGateway() + '/' + CRIMINAL + API_V2 + '/tipos-eventos-criminais/processo/'+idProcessoCriminal, {params: httpParams}).pipe(catchError(this._serverError));
    }

    getTiposEventosCriminaisAtivosPorTipo( tipoProcesso: string): Observable<TipoEventoCriminal[]> {
        const httpParams = new HttpParams().set('ativo', 'true');
        return this.httpClient.get<TipoEventoCriminal[]>(this.config.getUrlApiGateway() + '/' + CRIMINAL + API_V2 + '/tipos-eventos-criminais/tipo-processo/'+tipoProcesso, {params: httpParams}).pipe(catchError(this._serverError));
    }

    deleteEventosCriminais(idProcessoCriminal: number, idProcessoParteLegacy: number, eventoCriminal: EventoCriminal): Observable<any> {
        return this.httpClient.request<any>('delete', this.config.getUrlApiGateway() + '/' + CRIMINAL + '/eventosCriminais/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy + '/idEventoCriminal/' + eventoCriminal.id, { body: eventoCriminal });
    }

    getMotivosSuspensaoProcesso() : Observable<MotivoSuspensaoProcesso[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/motivosSuspensaoProcesso").pipe(catchError(this._serverError));
    }

    postDecisaoInstanciaSuperiorParte(idProcessoCriminal : number, idProcessoParteLegacy : number, decisaoInstanciaSuperior : DecisaoInstanciaSuperior) : Observable<DecisaoInstanciaSuperior[]>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/decisoesInstanciaSuperior/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, decisaoInstanciaSuperior).pipe(catchError(this._serverError));
    }

    alterarDecisaoInstanciaSuperiorParte(idProcessoCriminal : number, idProcessoParteLegacy : number, decisaoInstanciaSuperior : DecisaoInstanciaSuperior) : Observable<DecisaoInstanciaSuperior[]> {
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/decisoesInstanciaSuperior/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, decisaoInstanciaSuperior).pipe(catchError(this._serverError));
    }

    getTiposRecursos() : Observable<TipoRecurso[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/tiposRecurso").pipe(catchError(this._serverError));
    }

    getRegimes(): Observable<Regime[]>{
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/regimes").pipe(catchError(this._serverError));
    }

    postSentencaAbsolutoriaParte(idProcessoCriminal : number, idProcessoParteLegacy : number, sentencaAbsolutoria : SentencaAbsolutoria) : Observable<SentencaAbsolutoria[]>{
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/sentencasAbsolutorias/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, sentencaAbsolutoria).pipe(catchError(this._serverError));
    }

    alterarSentencaAbsolutoriaParte(idProcessoCriminal : number, idProcessoParteLegacy : number, sentencaAbsolutoria : SentencaAbsolutoria) : Observable<SentencaAbsolutoria[]> {
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/sentencasAbsolutorias/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, sentencaAbsolutoria).pipe(catchError(this._serverError));
    }

    postSentencaExtincaoPunibilidadeParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
            sentencaExtincaoPunibilidade: SentencaExtincaoPunibilidade): Observable<SentencaExtincaoPunibilidade[]> {
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/sentencasExtincaoPunibilidade/processos/' + idProcessoCriminal
            + '/partes/' + idProcessoParteLegacy, sentencaExtincaoPunibilidade).pipe(catchError(this._serverError));
    }

    alterarSentencaExtincaoPunibilidadeParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        sentencaExtincaoPunibilidade: SentencaExtincaoPunibilidade): Observable<SentencaExtincaoPunibilidade[]> {
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/sentencasExtincaoPunibilidade/processos/' + idProcessoCriminal
            + '/partes/' + idProcessoParteLegacy, sentencaExtincaoPunibilidade).pipe(catchError(this._serverError));
    }

    postCitacaoParte(idProcessoCriminal: number,
                     idProcessoParteLegacy: number,
                     citacao: Citacao): Observable<Citacao[]> {
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
        + '/citacoes/processos/' + idProcessoCriminal + '/partes/'
        + idProcessoParteLegacy, citacao).pipe(catchError(this._serverError));
    }

    alterarCitacaoParte(idProcessoCriminal: number,
                        idProcessoParteLegacy: number,
                        citacao: Citacao): Observable<Citacao[]> {
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
        + '/citacoes/processos/' + idProcessoCriminal + '/partes/'
        + idProcessoParteLegacy, citacao).pipe(catchError(this._serverError));
    }

    postRecebimentoAditamentoDenunciaParte(
        idProcessoCriminal: number,
        idProcessoParteLegacy: number,
        recebimentoAditamentoDenuncia: RecebimentoAditamentoDenuncia): Observable<RecebimentoAditamentoDenuncia[]> {
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/recebimentosAditamentoDenuncia/processos/' + idProcessoCriminal + '/partes/'
            + idProcessoParteLegacy, recebimentoAditamentoDenuncia).pipe(catchError(this._serverError));
    }

    alterarRecebimentoAditamentoDenunciaParte(
        idProcessoCriminal: number,
        idProcessoParteLegacy: number,
        recebimentoAditamentoDenuncia: RecebimentoAditamentoDenuncia): Observable<RecebimentoAditamentoDenuncia[]> {
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/recebimentosAditamentoDenuncia/processos/' + idProcessoCriminal + '/partes/'
            + idProcessoParteLegacy, recebimentoAditamentoDenuncia).pipe(catchError(this._serverError));
    }

    postSentencaImpronuncia(idProcessoCriminal: number,
                            idProcessoParteLegacy: number,
                            sentencaImpronuncia: SentencaImpronuncia): Observable<SentencaImpronuncia[]> {
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/sentencasImpronuncia/processos/' + idProcessoCriminal + '/partes/'
            + idProcessoParteLegacy, sentencaImpronuncia).pipe(catchError(this._serverError));
    }

    alterarSentencaImpronuncia(idProcessoCriminal: number,
                               idProcessoParteLegacy: number,
                               sentencaImpronuncia: SentencaImpronuncia): Observable<SentencaImpronuncia[]> {
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/sentencasImpronuncia/processos/' + idProcessoCriminal + '/partes/'
            + idProcessoParteLegacy, sentencaImpronuncia).pipe(catchError(this._serverError));
    }

    postSentencaPronuncia(idProcessoCriminal: number,
                          idProcessoParteLegacy: number,
                          sentencaPronuncia: SentencaPronuncia): Observable<SentencaPronuncia[]> {
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/sentencasPronuncia/processos/' + idProcessoCriminal + '/partes/'
            + idProcessoParteLegacy, sentencaPronuncia).pipe(catchError(this._serverError));
    }

    alterarSentencaPronuncia(idProcessoCriminal: number,
                             idProcessoParteLegacy: number,
                             sentencaPronuncia: SentencaPronuncia): Observable<SentencaPronuncia[]> {
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/sentencasPronuncia/processos/' + idProcessoCriminal + '/partes/'
            + idProcessoParteLegacy, sentencaPronuncia).pipe(catchError(this._serverError));
    }

    postDesclassificacaoProcesso(idProcessoCriminal: number,
                                 idProcessoParteLegacy: number,
                                 desclassificacaoProcesso: DesclassificacaoProcesso): Observable<DesclassificacaoProcesso[]> {
        return this.httpClient.post<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/desclassificacoesProcesso/processos/' + idProcessoCriminal + '/partes/'
            + idProcessoParteLegacy, desclassificacaoProcesso).pipe(catchError(this._serverError));
    }

    alterarDesclassificacaoProcesso(idProcessoCriminal: number,
                                    idProcessoParteLegacy: number,
                                    desclassificacaoProcesso: DesclassificacaoProcesso): Observable<DesclassificacaoProcesso[]> {
        return this.httpClient.put<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/desclassificacoesProcesso/processos/' + idProcessoCriminal + '/partes/'
            + idProcessoParteLegacy, desclassificacaoProcesso).pipe(catchError(this._serverError));
    }

    remeterAoSeeu(
                idProcessoCriminal: number,
                idProcessoLegacy: number,
                orgaoJulgador: string,
                dataDistribuicao: Date,
                guia: any,
                reu: any,
                processoCriminal: Processo,
                recebimentoDenuncia: EventoCriminal,
                sentencaCondenatoria: SentencaCondenatoria,
                sentencaPronuncia: SentencaPronuncia,
                decisaoInstanciaSuperior: DecisaoInstanciaSuperior,
                observacao: string,
                fugas: Fuga[],
                prisoes: Prisao[],
                solturas: Soltura[],
                documentos: any[]) : Observable<any>{

        let url = this.config.getUrlApiGateway() + '/' + CRIMINAL;
        
        return this.httpClient.post<any>(url + '/processos/' + idProcessoCriminal + '/remessa-seeu', 
            {
                idProcessoLegacy, orgaoJulgador, dataDistribuicao, guia, reu, processoCriminal, recebimentoDenuncia, 
                sentencaCondenatoria, sentencaPronuncia, decisaoInstanciaSuperior, observacao, fugas, prisoes, solturas, documentos
            }
        ).pipe(catchError(this._serverError));
    }

    getProcessosSeeuPorNomeSentenciado(nomeSentenciado: string) {
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/seeu/processos/' + nomeSentenciado).pipe(catchError(this._serverError));;
    }

    getComarcasSeeu() {
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/seeu/comarcas').pipe(catchError(this._serverError));
    }
    
    getAreasDeVaraSeeu(idComarca: number) {
        return this.httpClient.get<any>(this.config.getUrlApiGateway() + '/' + CRIMINAL + '/seeu/comarcas/' + idComarca + '/areas-de-vara').pipe(catchError(this._serverError));
    }
    
    getTiposPenaExecucao(): Observable<any[]> {
        return this.httpClient.get<any[]>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/tiposPenaExecucao").pipe(catchError(this._serverError));
    }

    getRecorrentes(): Observable<Recorrente[]> {
        return this.httpClient.get<Recorrente[]>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/recorrentes").pipe(catchError(this._serverError));
    }

    getOrgaosJulgadores(): Observable<OrgaoJulgador[]> {
        return this.httpClient.get<OrgaoJulgador[]>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/orgaosJulgadores").pipe(catchError(this._serverError));
    }

    getMedidasSeguranca(): Observable<MedidaSeguranca[]> {
        return this.httpClient.get<MedidaSeguranca[]>(this.config.getUrlApiGateway() + '/' + CRIMINAL + "/medidasSeguranca").pipe(catchError(this._serverError));
    }

    criarDesmembramentoICParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        desmembramento: DesmembramentoIC): Observable<DesmembramentoIC> {
        return this.httpClient.post<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/desmembramento/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            desmembramento)
            .pipe(catchError(this._serverError));
    }

    alterarDesmembramentoICParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        desmembramento: DesmembramentoIC): Observable<DesmembramentoIC> {
        return this.httpClient.put<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/desmembramento/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            desmembramento)
    }
    
    criarSentencaAbsolutoriaImpropriaParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        sentencaAbsolutoriaImpropria: SentencaAbsolutoriaImpropria): Observable<SentencaAbsolutoriaImpropria> {
        return this.httpClient.post<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/sentencas-absolutorias-improprias/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            sentencaAbsolutoriaImpropria)
            .pipe(catchError(this._serverError));
    }

    alterarSentencaAbsolutoriaImpropriaParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        sentencaAbsolutoriaImpropria: SentencaAbsolutoriaImpropria): Observable<SentencaAbsolutoriaImpropria> {
        return this.httpClient.put<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/sentencas-absolutorias-improprias/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            sentencaAbsolutoriaImpropria)
            .pipe(catchError(this._serverError));
    }

    
    criarLitispendenciaCoisaJulgadaParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        litispendenciaCoisaJulgada: LitispendenciaCoisaJulgada): Observable<LitispendenciaCoisaJulgada> {
        return this.httpClient.post<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/litispendencias-coisa-julgada/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            litispendenciaCoisaJulgada)
            .pipe(catchError(this._serverError));
    }

    alterarLitispendenciaCoisaJulgadaParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        litispendenciaCoisaJulgada: LitispendenciaCoisaJulgada): Observable<LitispendenciaCoisaJulgada> {
        return this.httpClient.put<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/litispendencias-coisa-julgada/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            litispendenciaCoisaJulgada)
            .pipe(catchError(this._serverError));
    }

    postPresoOutroProcessoParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        presoOutroProcesso: PresoOutroProcesso): Observable<PresoOutroProcesso> {
        return this.httpClient.post<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/presos-outro-processo/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, presoOutroProcesso)
            .pipe(catchError(this._serverError));
    }

    alterarPresoOutroProcessoParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        presoOutroProcesso: PresoOutroProcesso): Observable<PresoOutroProcesso> {
        return this.httpClient.put<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/presos-outro-processo/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, presoOutroProcesso)
            .pipe(catchError(this._serverError));
    }

    getUnidadesPrisionaisBeanPorSiglaUf(siglaUf: string): Observable<UnidadePrisional[]> {
        return this.httpClient.get<UnidadePrisional[]>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL + '/unidadesPrisionais/filter/estados/' + siglaUf)
            .pipe(catchError(this._serverError));
    }
    
    criarTransacaoPenalParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        transacaoPenal: TransacaoPenal): Observable<TransacaoPenal> {
        return this.httpClient.post<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/transacao-penal/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            transacaoPenal)
            .pipe(catchError(this._serverError));
    }

    alterarTransacaoPenalParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        transacaoPenal: TransacaoPenal): Observable<TransacaoPenal> {
        return this.httpClient.put<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/transacao-penal/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            transacaoPenal)
            .pipe(catchError(this._serverError));
    }
    
    postRevogacaoSuspensaoCondicionalProcessoParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        revogacaoSuspensaoCondicionalProcesso: RevogacaoSuspensaoCondicionalProcesso): Observable<RevogacaoSuspensaoCondicionalProcesso> {
        return this.httpClient.post<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/revogacoes-suspensao-condicional-processo/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            revogacaoSuspensaoCondicionalProcesso)
            .pipe(catchError(this._serverError));
    }

    alterarRevogacaoSuspensaoCondicionalProcessoParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        revogacaoSuspensaoCondicionalProcesso: RevogacaoSuspensaoCondicionalProcesso): Observable<RevogacaoSuspensaoCondicionalProcesso> {
        return this.httpClient.put<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/revogacoes-suspensao-condicional-processo/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            revogacaoSuspensaoCondicionalProcesso)
            .pipe(catchError(this._serverError));
    }
    
    criarNulidadeProcessoParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        nulidadeProcesso: NulidadeProcesso): Observable<NulidadeProcesso> {
        return this.httpClient.post<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/nulidades-processos/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            nulidadeProcesso)
            .pipe(catchError(this._serverError));
    }

    alterarNulidadeProcessoParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        nulidadeProcesso: NulidadeProcesso): Observable<NulidadeProcesso> {
        return this.httpClient.put<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/nulidades-processos/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            nulidadeProcesso)
            .pipe(catchError(this._serverError));
    }
    
    criarAusenciaCondicoesAcaoParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        ausenciaCondicoesAcao: AusenciaCondicoesAcao): Observable<AusenciaCondicoesAcao> {
        return this.httpClient.post<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/ausencias-condicoes-acao/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            ausenciaCondicoesAcao)
            .pipe(catchError(this._serverError));
    }

    alterarAusenciaCondicoesAcaoParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        ausenciaCondicoesAcao: AusenciaCondicoesAcao): Observable<AusenciaCondicoesAcao> {
        return this.httpClient.put<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/ausencias-condicoes-acao/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            ausenciaCondicoesAcao)
            .pipe(catchError(this._serverError));
    }

    criarDeclinioCompetenciaParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        declinioCompetencia: DeclinioCompetencia): Observable<DeclinioCompetencia> {
        return this.httpClient.post<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/declinios-competencia/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            declinioCompetencia)
            .pipe(catchError(this._serverError));
    }

    alterarDeclinioCompetenciaParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        declinioCompetencia: DeclinioCompetencia): Observable<DeclinioCompetencia> {
        return this.httpClient.put<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/declinios-competencia/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            declinioCompetencia)
            .pipe(catchError(this._serverError));
    }

    criarHomologacaoComposicaoDanoCivilParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        homologacaoComposicaoDanoCivil: HomologacaoComposicaoDanoCivil): Observable<HomologacaoComposicaoDanoCivil> {
        return this.httpClient.post<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/homologacoes-composicoes-danos-civis/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            homologacaoComposicaoDanoCivil)
            .pipe(catchError(this._serverError));
    }

    alterarHomologacaoComposicaoDanoCivilParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        homologacaoComposicaoDanoCivil: HomologacaoComposicaoDanoCivil): Observable<HomologacaoComposicaoDanoCivil> {
        return this.httpClient.put<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/homologacoes-composicoes-danos-civis/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            homologacaoComposicaoDanoCivil)
            .pipe(catchError(this._serverError));
    }
    
    criarTrancamentoParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        trancamento: Trancamento): Observable<Trancamento> {
        return this.httpClient.post<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/trancamento/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            trancamento)
            .pipe(catchError(this._serverError));
    }

    alterarTrancamentoParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        trancamento: Trancamento): Observable<Trancamento> {
        return this.httpClient.put<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/trancamento/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            trancamento)
            .pipe(catchError(this._serverError));
    }
    
    postInternacaoParte(idProcessoCriminal: number, idProcessoParteLegacy: number, internacao: Internacao): Observable<Internacao> {
        return this.httpClient.post<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
                + '/internacoes/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, internacao)
            .pipe(catchError(this._serverError));
    }

    alterarInternacaoParte(idProcessoCriminal: number, idProcessoParteLegacy: number, internacao: Internacao): Observable<Internacao> {
        return this.httpClient.put<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
                + '/internacoes/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, internacao)
            .pipe(catchError(this._serverError));
    }

    postSentencaInfracionalParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        sentencaInfracional: SentencaInfracional): Observable<SentencaInfracional> {
        return this.httpClient.post<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
                + '/sentencasInfracionais/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, sentencaInfracional)
            .pipe(catchError(this._serverError));
    }

    alterarSentencaInfracionalParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
            sentencaInfracional: SentencaInfracional): Observable<SentencaInfracional> {
        return this.httpClient.put<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
                + '/sentencasInfracionais/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, sentencaInfracional)
            .pipe(catchError(this._serverError));
    }

    postSentencaExtincaoMedidaSocioeducativaParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        sentencaExtincaoMedidaSocioeducativa: SentencaExtincaoMedidaSocioeducativa): Observable<SentencaExtincaoMedidaSocioeducativa> {
        return this.httpClient.post<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL + '/sentencasExtincaoMedidaSocioeducativa/processos/'
                + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, sentencaExtincaoMedidaSocioeducativa)
            .pipe(catchError(this._serverError));
    }

    alterarSentencaExtincaoMedidaSocioeducativaParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        sentencaExtincaoMedidaSocioeducativa: SentencaExtincaoMedidaSocioeducativa): Observable<SentencaExtincaoMedidaSocioeducativa> {
        return this.httpClient.put<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL + '/sentencasExtincaoMedidaSocioeducativa/processos/'
                + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, sentencaExtincaoMedidaSocioeducativa)
            .pipe(catchError(this._serverError));
    }

    postEvasaoParte(idProcessoCriminal: number, idProcessoParteLegacy: number, evasao: Evasao): Observable<Evasao> {
        return this.httpClient.post<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
                + '/evasoes/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, evasao)
            .pipe(catchError(this._serverError));
    }

    alterarEvasaoParte(idProcessoCriminal: number, idProcessoParteLegacy: number, evasao: Evasao): Observable<Evasao> {
        return this.httpClient.put<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
                + '/evasoes/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, evasao)
            .pipe(catchError(this._serverError));
    }

    postSemiliberdadeParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
            semiliberdade: Semiliberdade): Observable<Semiliberdade> {
        return this.httpClient.post<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
                + '/semiliberdades/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, semiliberdade)
            .pipe(catchError(this._serverError));
    }

    alterarSemiliberdadeParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
            semiliberdade: Semiliberdade): Observable<Semiliberdade> {
        return this.httpClient.put<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
                + '/semiliberdades/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, semiliberdade)
            .pipe(catchError(this._serverError));
    }
    
    postRemissaoParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        semiliberdade: Semiliberdade): Observable<Remissao> {
        return this.httpClient.post<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
                + '/remissoes/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, semiliberdade)
            .pipe(catchError(this._serverError));
    }

    alterarRemissaoParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
            semiliberdade: Semiliberdade): Observable<Remissao> {
        return this.httpClient.put<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
                + '/remissoes/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy, semiliberdade)
            .pipe(catchError(this._serverError));
    }

    postSuspensaoMedidaSocioeducativaParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        suspensaoMedidaSocioeducativa: SuspensaoMedidaSocioeducativa): Observable<SuspensaoMedidaSocioeducativa> {
        return this.httpClient.post<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
                + '/suspensoesMedidasSocioeducativas/processos/' + idProcessoCriminal + '/partes/'
                + idProcessoParteLegacy, suspensaoMedidaSocioeducativa)
            .pipe(catchError(this._serverError));
    }

    alterarSuspensaoMedidaSocioeducativaParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        suspensaoMedidaSocioeducativa: SuspensaoMedidaSocioeducativa): Observable<SuspensaoMedidaSocioeducativa> {
        return this.httpClient.put<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
                + '/suspensoesMedidasSocioeducativas/processos/' + idProcessoCriminal + '/partes/'
                + idProcessoParteLegacy, suspensaoMedidaSocioeducativa)
            .pipe(catchError(this._serverError));
    }

    postDecisaoParte(idProcessoCriminal: number, idProcessoParteLegacy: number, decisao: Decisao): Observable<Decisao> {
        return this.httpClient.post<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL + '/decisoes/processos/' + idProcessoCriminal + '/partes/'
                + idProcessoParteLegacy, decisao)
            .pipe(catchError(this._serverError));
    }

    alterarDecisaoParte(idProcessoCriminal: number, idProcessoParteLegacy: number, decisao: Decisao): Observable<Decisao> {
        return this.httpClient.put<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL + '/decisoes/processos/' + idProcessoCriminal + '/partes/'
                + idProcessoParteLegacy, decisao)
            .pipe(catchError(this._serverError));
    }  

    criarArquivamentoIncidenciaParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        arquivamentoIncidencia: ArquivamentoIncidencia): Observable<ArquivamentoIncidencia> {
        return this.httpClient.post<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/arquivamentos-incidencias/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            arquivamentoIncidencia)
            .pipe(catchError(this._serverError));
    }

    alterarArquivamentoIncidenciaParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        arquivamentoIncidencia: ArquivamentoIncidencia): Observable<ArquivamentoIncidencia> {
        return this.httpClient.put<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/arquivamentos-incidencias/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            arquivamentoIncidencia)
            .pipe(catchError(this._serverError));
    }

    criarSentencaAbsolvicaoSumariaParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        sentencaAbsolvicaoSumaria: SentencaAbsolvicaoSumaria): Observable<SentencaAbsolvicaoSumaria> {
        return this.httpClient.post<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/sentencas-absolvicao-sumaria/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            sentencaAbsolvicaoSumaria)
            .pipe(catchError(this._serverError));
    }

    alterarSentencaAbsolvicaoSumariaParte(idProcessoCriminal: number, idProcessoParteLegacy: number,
        sentencaAbsolvicaoSumaria: SentencaAbsolvicaoSumaria): Observable<SentencaAbsolvicaoSumaria> {
        return this.httpClient.put<any>(
            this.config.getUrlApiGateway() + '/' + CRIMINAL
            + '/sentencas-absolvicao-sumaria/processos/' + idProcessoCriminal + '/partes/' + idProcessoParteLegacy,
            sentencaAbsolvicaoSumaria)
            .pipe(catchError(this._serverError));
    }
}
