import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericService } from './generic.service';
import { AppConfig } from '../../app.config';
import { PJE_LEGACY } from './rest-servers';


@Injectable()
export class OrgaoJulgadorService extends GenericService<any>{

    constructor(private http: HttpClient, private config:AppConfig) { 
        super(http, config);
        this.setRestPath = PJE_LEGACY + '/painelUsuario/orgaosJulgadores';
    }

}