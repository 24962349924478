import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GenericService } from './generic.service';
import { AppConfig } from '../../app.config';
import { PJE_LEGACY } from './rest-servers';
import {catchError} from "rxjs/operators";
import {Observable} from "rxjs";


@Injectable()
export class OrgaoJulgadorColegiadoService extends GenericService<any>{

	  private painelControllerPath: String = PJE_LEGACY + '/painelUsuario/orgaosJulgadoresPorColegiado/';

	
    constructor(private http: HttpClient, private config:AppConfig) { 
        super(http, config);
        this.setRestPath = PJE_LEGACY + '/painelUsuario/orgaosJulgadoresColegiado';
    }
	
	
	filtraOrgaosPorColegiado(orgaoJulgadorColegiado:number): Observable<any> {
		return this.http.get<any>(this.getLegacyUrl(PJE_LEGACY + '/painelUsuario/orgaosJulgadoresPorColegiado/'+orgaoJulgadorColegiado)).pipe(catchError(this._serverError));
	}

}