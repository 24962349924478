import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PjeComponentsModule } from './components/pje-components.module';
import { PjePipesModule } from './pipes/pje-pipes.module';
import { PjeDirectivesModule } from './directives/pje-directives.module';

@NgModule({
    imports: [ 
        CommonModule, 
        FormsModule,
        PjeComponentsModule,
        PjePipesModule,
        PjeDirectivesModule
    ],
    exports: [ 
        CommonModule, 
        FormsModule,
        PjeComponentsModule,
        PjePipesModule,
        PjeDirectivesModule
    ]
})
export class PjeSharedModule { }
