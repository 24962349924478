import { Injectable } from '@angular/core';
import { AppConfig } from '../../app.config';
import { GenericMessages } from 'app/generic-messages.enum';

@Injectable()
export class UtilService {

    constructor( private config: AppConfig ) { }

    url(...content: any[]): string{

        var urlFinal = content[0];
        
        if (content.length > 1){
            urlFinal = urlFinal.concat("?");
        }
        
        for (var i=1, t = content.length; i < t; i++) {
            
            urlFinal = urlFinal.concat(content[i]);
            
            if ((i +1) % 2 === 0) {
                urlFinal = urlFinal.concat("=");
            }
            else if (i+1  < t) {
                urlFinal = urlFinal.concat("&");
            }			
        }
        return urlFinal;        
    }

    webRoot(): string {
        return this.config.getUrlPjelegacy();
    }

    pjeLegacyContextPath(): string {
        return this.config.getContextPathPjeLegacy();
    }

    codigoSeguranca(): string {
        return this.config.pjeCodigoSeguranca;
    }

    mobile(): string {
        return this.config.pjeMobile;
    }

    tipoEditor(): string {
        return this.config.tipoEditor;
    }

    primeiraInstancia(): boolean {
        return this.config.getInstancia() === '1';
    }

    urlMarketplace(): string {
        return this.config.getUrlPDPJMarketplace();
    }

    srvSemPath(url: string) {
		return '/seam/resource/rest' + url;
	};

    jsessionid():string{
        return this.getCookie("JSESSIONID");
    }

    sha_256(message: string): string{
        return this.sha256Hash(message);
    }

    private getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(window.document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    /**
     * Implementação síncrona da função hash SHA-256
     * Evitando a implementação nativa baseada em Promise.
     * 
     * Fonte: https://geraintluff.github.io/sha256/
     */
    private sha256Hash(message: string): string{
    
        var sha256 = function sha256(ascii) {
            function rightRotate(value, amount) {
                return (value>>>amount) | (value<<(32 - amount));
            };
            
            var mathPow = Math.pow;
            var maxWord = mathPow(2, 32);
            var lengthProperty = 'length';
            var i = 0, j = 0; // Used as a counter across the whole file
            var result = '';
        
            var words = [];
            var asciiBitLength = ascii[lengthProperty]*8;         
            
            var hash = [], k = [];
            var primeCounter = 0;
        
            var isComposite = {};
            for (var candidate = 2; primeCounter < 64; candidate++) {
                if (!isComposite[candidate]) {
                    for (i = 0; i < 313; i += candidate) {
                        isComposite[i] = candidate;
                    }
                    hash[primeCounter] = (mathPow(candidate, .5)*maxWord)|0;
                    k[primeCounter++] = (mathPow(candidate, 1/3)*maxWord)|0;
                }
            }
            
            ascii += '\x80'; // Append '1' bit (plus zero padding)
            while (ascii[lengthProperty]%64 - 56) ascii += '\x00'; // More zero padding
            for (i = 0; i < ascii[lengthProperty]; i++) {
                j = ascii.charCodeAt(i);
                if (j>>8) return; // ASCII check: only accept characters in range 0-255
                words[i>>2] |= j << ((3 - i)%4)*8;
            }
            words[words[lengthProperty]] = ((asciiBitLength/maxWord)|0);
            words[words[lengthProperty]] = (asciiBitLength)
            
            // process each chunk
            for (j = 0; j < words[lengthProperty];) {
                var w = words.slice(j, j += 16); // The message is expanded into 64 words as part of the iteration
                var oldHash = hash;
                // This is now the "working hash", often labelled as variables a...g
                // (we have to truncate as well, otherwise extra entries at the end accumulate
                hash = hash.slice(0, 8);
                
                for (i = 0; i < 64; i++) {
                    var i2 = i + j;
                    // Expand the message into 64 words
                    // Used below if 
                    var w15 = w[i - 15], w2 = w[i - 2];
        
                    // Iterate
                    var a = hash[0], e = hash[4];
                    var temp1 = hash[7]
                        + (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) // S1
                        + ((e&hash[5])^((~e)&hash[6])) // ch
                        + k[i]
                        // Expand the message schedule if needed
                        + (w[i] = (i < 16) ? w[i] : (
                                w[i - 16]
                                + (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15>>>3)) // s0
                                + w[i - 7]
                                + (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2>>>10)) // s1
                            )|0
                        );
                    // This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
                    var temp2 = (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) // S0
                        + ((a&hash[1])^(a&hash[2])^(hash[1]&hash[2])); // maj
                    
                    hash = [(temp1 + temp2)|0].concat(hash); // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
                    hash[4] = (hash[4] + temp1)|0;
                }
                
                for (i = 0; i < 8; i++) {
                    hash[i] = (hash[i] + oldHash[i])|0;
                }
            }
            
            for (i = 0; i < 8; i++) {
                for (j = 3; j + 1; j--) {
                    var b = (hash[i]>>(j*8))&255;
                    result += ((b < 16) ? 0 : '') + b.toString(16);
                }
            }
            return result;
        };
               
        return sha256(message);
    }
        

    validarCamposObr(form, notificationMessageService) {
        const ctrl = form.form.controls;
        for (const comp in ctrl) {
            if (ctrl[comp].invalid) {
                ctrl[comp].touched = true;
            }
        }
        notificationMessageService.sendWarn(GenericMessages.EMPTY, GenericMessages.CAMPOS_OBRIGATORIOS);
    }


}