import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pjeSimpleFilter'
})

/*
 * Filtra a partir de uma lista 
 * um valor, retornando uma sublista
 * com os resultados 
 * 
 * uso
 * 
 * let item of lista | pjeSimpleFilter: termo : 'propriedade' 
 * 
 * exemplo
 * 
 * let etiqueta of listaEtiquetas.etiquetas | pjeSimpleFilter: pesquisarEtiquetas : 'nomeTag' 
 */
export class SimpleFilterPipe implements PipeTransform {
    transform(lista: any[], ...args: any[]): any {

        let prop = args[args.length - 1];
        let term = args[0];

        let ret : any[] = lista.filter(item => {
            return JSON.stringify(item[prop] ? item[prop] : '').toLowerCase().includes(term.toLowerCase());
        });

        return ret
    }
}