import { ListaProcessosTarefa } from '../model/lista-processos-tarefa';

export const PROCESSOS_TAREFA: ListaProcessosTarefa = 
{
   count:28,
   processos:[
      {
         "idTaskInstance":129925,
         "idTaskInstanceProximo":129949,
         "poloAtivo":"Han Solo",
         "poloPassivo":"Luke Skywalker",
         "processo":{
            "id":647,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800013-07.2014.8.10.0006",
               "sequencia":800013,
               "ano":2014,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":7
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2014-09-12T23:26:35.290+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[
            "teste",
            "teste2"
         ],
         "tagsProcessoList":[
            {
               "id":12,
               "nomeTag":"teste",
               "favorita":null,
               "idUsuario":1791,
               "idProcesso":647
            },
            {
               "id":14,
               "nomeTag":"teste2",
               "favorita":null,
               "idUsuario":1791,
               "idProcesso":647
            }
         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":true,
         "assuntoPrincipal":"Abatimento proporcional do preço"
      },
      {
         "idTaskInstance":129949,
         "idTaskInstanceProximo":129987,
         "poloAtivo":"Luke Skywalker",
         "poloPassivo":"Darth Vader",
         "processo":{
            "id":650,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800014-89.2014.8.10.0006",
               "sequencia":800014,
               "ano":2014,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":89
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2014-09-15T16:10:19.981+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[
            "teste"
         ],
         "tagsProcessoList":[
            {
               "id":12,
               "nomeTag":"teste",
               "favorita":null,
               "idUsuario":1791,
               "idProcesso":650
            }
         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"Abatimento proporcional do preço"
      },
      {
         "idTaskInstance":129987,
         "idTaskInstanceProximo":129992,
         "poloAtivo":"Luke Skywalker",
         "poloPassivo":"Darth Vader",
         "processo":{
            "id":652,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800016-59.2014.8.10.0006",
               "sequencia":800016,
               "ano":2014,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":59
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2014-09-15T16:30:10.785+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":true,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"Abatimento proporcional do preço"
      },
      {
         "idTaskInstance":129992,
         "idTaskInstanceProximo":130036,
         "poloAtivo":"Persei Cassiopeiae",
         "poloPassivo":"Persei Deneb",
         "processo":{
            "id":651,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800015-74.2014.8.10.0006",
               "sequencia":800015,
               "ano":2014,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":74
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2014-09-15T16:47:27.036+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"Abatimento proporcional do preço"
      },
      {
         "idTaskInstance":130036,
         "idTaskInstanceProximo":130065,
         "poloAtivo":"Han Solo",
         "poloPassivo":"Luke Skywalker",
         "processo":{
            "id":654,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800018-29.2014.8.10.0006",
               "sequencia":800018,
               "ano":2014,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":29
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2014-09-15T20:49:35.045+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"Abatimento proporcional do preço"
      },
      {
         "idTaskInstance":130065,
         "idTaskInstanceProximo":130093,
         "poloAtivo":"Han Solo",
         "poloPassivo":"Luke Skywalker",
         "processo":{
            "id":655,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800019-14.2014.8.10.0006",
               "sequencia":800019,
               "ano":2014,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":14
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2014-09-16T18:12:48.075+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"Adimplemento e Extinção"
      },
      {
         "idTaskInstance":130093,
         "idTaskInstanceProximo":130112,
         "poloAtivo":"Puppis Deneb",
         "poloPassivo":"Coronae Camelopardalis",
         "processo":{
            "id":656,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800020-96.2014.8.10.0006",
               "sequencia":800020,
               "ano":2014,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":96
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2014-09-16T20:06:32.525+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      },
      {
         "idTaskInstance":130112,
         "idTaskInstanceProximo":130135,
         "poloAtivo":"Luke Skywalker",
         "poloPassivo":"Arae Aquilae",
         "processo":{
            "id":657,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800021-81.2014.8.10.0006",
               "sequencia":800021,
               "ano":2014,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":81
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2014-09-16T20:25:02.943+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      },
      {
         "idTaskInstance":130135,
         "idTaskInstanceProximo":130157,
         "poloAtivo":"Arae Aquilae",
         "poloPassivo":"SAMUEL DE AGUIAR RODRIGUES",
         "processo":{
            "id":658,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800022-66.2014.8.10.0006",
               "sequencia":800022,
               "ano":2014,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":66
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2014-09-18T20:10:35.532+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      },
      {
         "idTaskInstance":130157,
         "idTaskInstanceProximo":136214,
         "poloAtivo":"FAUSTO ANTONIO PEREIRA POSCHI",
         "poloPassivo":"TIAGO DA SILVEIRA SONAGLIO",
         "processo":{
            "id":659,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800023-51.2014.8.10.0006",
               "sequencia":800023,
               "ano":2014,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":51
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2014-09-18T20:20:15.381+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      },
      {
         "idTaskInstance":136214,
         "idTaskInstanceProximo":136254,
         "poloAtivo":"SERVICO FEDERAL DE PROCESSAMENTO DE DADOS (SERPRO)",
         "poloPassivo":"Arae Aquilae",
         "processo":{
            "id":887,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800077-80.2015.8.10.0006",
               "sequencia":800077,
               "ano":2015,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":80
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2015-10-14T18:27:56.027+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      },
      {
         "idTaskInstance":136254,
         "idTaskInstanceProximo":136391,
         "poloAtivo":"Arae Aquilae",
         "poloPassivo":"Ministério Público do Estado do Maranhão",
         "processo":{
            "id":894,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800080-35.2015.8.10.0006",
               "sequencia":800080,
               "ano":2015,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":35
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2015-10-16T15:51:13.659+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      },
      {
         "idTaskInstance":136391,
         "idTaskInstanceProximo":136414,
         "poloAtivo":"Sadir Omicron",
         "poloPassivo":"Arae Aquilae",
         "processo":{
            "id":903,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800087-27.2015.8.10.0006",
               "sequencia":800087,
               "ano":2015,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":27
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2015-10-23T17:49:51.340+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      },
      {
         "idTaskInstance":136414,
         "idTaskInstanceProximo":136434,
         "poloAtivo":"Luke Skywalker",
         "poloPassivo":"Darth Vader",
         "processo":{
            "id":905,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800088-12.2015.8.10.0006",
               "sequencia":800088,
               "ano":2015,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":12
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2015-10-23T18:56:56.819+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      },
      {
         "idTaskInstance":136434,
         "idTaskInstanceProximo":138348,
         "poloAtivo":"Luke Skywalker",
         "poloPassivo":"Darth Vader",
         "processo":{
            "id":909,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800089-94.2015.8.10.0006",
               "sequencia":800089,
               "ano":2015,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":94
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2015-11-03T17:01:50.850+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      },
      {
         "idTaskInstance":138348,
         "idTaskInstanceProximo":138467,
         "poloAtivo":"PF -SEM NOME",
         "poloPassivo":"PJ - SEM NOME",
         "processo":{
            "id":919,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800095-04.2015.8.10.0006",
               "sequencia":800095,
               "ano":2015,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":4
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2015-11-13T19:13:44.401+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      },
      {
         "idTaskInstance":138467,
         "idTaskInstanceProximo":138491,
         "poloAtivo":"Alexander Monteiro",
         "poloPassivo":"PESSOA SEM CPF",
         "processo":{
            "id":929,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800096-86.2015.8.10.0006",
               "sequencia":800096,
               "ano":2015,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":86
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2015-11-13T20:15:40.649+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      },
      {
         "idTaskInstance":138491,
         "idTaskInstanceProximo":138535,
         "poloAtivo":"Arae Aquilae",
         "poloPassivo":"Alexander Monteiro",
         "processo":{
            "id":931,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800097-71.2015.8.10.0006",
               "sequencia":800097,
               "ano":2015,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":71
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2015-11-13T20:33:06.658+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      },
      {
         "idTaskInstance":138535,
         "idTaskInstanceProximo":139428,
         "poloAtivo":"Luke Skywalker",
         "poloPassivo":"Darth Vader",
         "processo":{
            "id":932,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800098-56.2015.8.10.0006",
               "sequencia":800098,
               "ano":2015,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":56
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2015-11-16T17:00:51.660+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      },
      {
         "idTaskInstance":139428,
         "idTaskInstanceProximo":139449,
         "poloAtivo":"O zuerão",
         "poloPassivo":"O zuero master",
         "processo":{
            "id":941,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800103-78.2015.8.10.0006",
               "sequencia":800103,
               "ano":2015,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":78
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2015-11-18T17:05:13.515+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      },
      {
         "idTaskInstance":139449,
         "idTaskInstanceProximo":139526,
         "poloAtivo":"fafasasfasf",
         "poloPassivo":"o adaiosd",
         "processo":{
            "id":942,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800104-63.2015.8.10.0006",
               "sequencia":800104,
               "ano":2015,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":63
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2015-11-18T17:25:42.062+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      },
      {
         "idTaskInstance":139526,
         "idTaskInstanceProximo":139547,
         "poloAtivo":"PF SEM CNPJ",
         "poloPassivo":"PJ SEM CNPJ",
         "processo":{
            "id":946,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800106-33.2015.8.10.0006",
               "sequencia":800106,
               "ano":2015,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":33
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2015-11-19T17:48:01.126+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      },
      {
         "idTaskInstance":139547,
         "idTaskInstanceProximo":139577,
         "poloAtivo":"Darth Vader",
         "poloPassivo":"654654",
         "processo":{
            "id":947,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800107-18.2015.8.10.0006",
               "sequencia":800107,
               "ano":2015,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":18
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2015-11-19T18:00:27.166+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      },
      {
         "idTaskInstance":139577,
         "idTaskInstanceProximo":139597,
         "poloAtivo":"Arae Aquilae",
         "poloPassivo":"321321321321",
         "processo":{
            "id":948,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800108-03.2015.8.10.0006",
               "sequencia":800108,
               "ano":2015,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":3
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2015-11-19T18:31:06.968+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      },
      {
         "idTaskInstance":139597,
         "idTaskInstanceProximo":139627,
         "poloAtivo":"Luke Skywalker",
         "poloPassivo":"Darth Vader",
         "processo":{
            "id":949,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800109-85.2015.8.10.0006",
               "sequencia":800109,
               "ano":2015,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":85
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2015-11-20T12:13:09.051+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      },
      {
         "idTaskInstance":139627,
         "idTaskInstanceProximo":140306,
         "poloAtivo":"Darth Vader",
         "poloPassivo":"Luke Skywalker",
         "processo":{
            "id":951,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800111-55.2015.8.10.0006",
               "sequencia":800111,
               "ano":2015,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":55
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2015-11-20T17:23:12.420+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      },
      {
         "idTaskInstance":140306,
         "idTaskInstanceProximo":140326,
         "poloAtivo":"Luke Skywalker",
         "poloPassivo":"Darth Vader",
         "processo":{
            "id":917,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800094-19.2015.8.10.0006",
               "sequencia":800094,
               "ano":2015,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":19
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2016-10-26T19:42:19.136+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      },
      {
         "idTaskInstance":140326,
         "idTaskInstanceProximo":null,
         "poloAtivo":"Luke Skywalker",
         "poloPassivo":"Darth Vader",
         "processo":{
            "id":1006,
            "dataInicio":null,
            "numeroProcesso":null,
            "numero":{
               "numero":"0800009-96.2016.8.10.0006",
               "sequencia":800009,
               "ano":2016,
               "unidadeJudiciaria":810,
               "origem":6,
               "digitoVerificador":96
            },
            "status":null,
            "revisado":false,
            "deveMarcarAudiencia":false,
            "apreciadoJusticaGratuita":false,
            "selecionadoJulgamento":false,
            "inOutraInstancia":false,
            "selecionadoPauta":false,
            "mandadoDevolvido":false,
            "orgaoJulgador":{
               "id":null,
               "nome":"1º Juizado Especial Cível e das Relações de Consumo de São Luis",
               "codigoOrigem":null,
               "ativo":null
            },
            "orgaoJulgadorColegiado":{
               "id":null,
               "nome":null,
               "ativo":null
            },
            "classe":{
               "id":null,
               "codClasseJudicial":null,
               "codClasseOutro":null,
               "classeJudicial":"PJEC",
               "classeJudicialSigla":null,
               "natureza":null,
               "norma":null,
               "leiArtigo":null,
               "lei":null,
               "inicial":null,
               "recursal":null,
               "incidental":null,
               "ativo":true,
               "segredoJustica":false,
               "complementar":false,
               "exigeAutoridade":false,
               "permiteAutoridade":false,
               "classeJudicialPai":null,
               "valorPeso":1.0,
               "poloAtivo":null,
               "poloPassivo":null,
               "classeJudicialGlossario":null,
               "ignoraPrevencao":null,
               "ignoraCompensacao":null,
               "possuiCusta":null,
               "pauta":null,
               "pautaAntecRevisao":false,
               "exigeFiscalLei":false,
               "possuiFilhos":false,
               "classeJudicialCompleto":null,
               "mensagem":null,
               "controlaValorCausa":false,
               "pisoValorCausa":null,
               "tetoValorCausa":null,
               "designacaoAudienciaErroValorCausa":true,
               "jusPostulandi":null,
               "icone":null,
               "reclamaPoloPassivo":true,
               "exigeNumeracaoPropria":true,
               "permiteNumeracaoManual":false,
               "habilitarMascaraProcessoReferencia":false
            },
            "partes":[

            ]
         },
         "dataChegada":"2016-10-27T13:01:35.289+0000",
         "conferido":false,
         "tags":null,
         "nomeTarefa":"Aguarda julgamento de instância superior",
         "arquivos":[

         ],
         "tipoDocumentoAssinatura":null,
         "sigiloso":false,
         "prioridade":false,
         "podeAssinar":null,
         "dataAlteracaoDocumentoAssinatura":null,
         "nomeUsuarioAlteracaoDocumentoAssinatura":null,
         "tagsList":[

         ],
         "tagsProcessoList":[

         ],
         "loginResponsavelTarefa":null,
         "nomeResponsavelTarefa":null,
         "podeMovimentarEmLote":null,
         "assuntoPrincipal":"ASSISTÊNCIA SOCIAL"
      }
   ]
}