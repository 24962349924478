import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'pje-datatable',
	templateUrl: './pje-datatable.component.html',
	styleUrls: ['./pje-datatable.component.scss']
})
export class PjeDatatableComponent implements OnInit {

	@Input("value")
	value : any[];

	@Input("columns")
	columns : any[];

	constructor() { }

	ngOnInit() {
	}

}
