
import {catchError,  map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, config } from 'rxjs';

import { GenericService } from './generic.service';
import { AppConfig } from '../../app.config';
import { PJE_LEGACY } from './rest-servers';
import { Usuario } from '../../model/usuario.model';

@Injectable()
export class AuthenticationService extends GenericService<any>{
    private srvPath: string = PJE_LEGACY + '/usuario';

    private _usuarioLogado : any;

    constructor(private http: HttpClient, private config: AppConfig) { 
        super(http, config);
    }
    
    currentUser(): Observable<Usuario>{
        return this.http.get(this.getLegacyUrl(this.srvPath + '/currentUser')).pipe(
            catchError(this._serverError));
    }

    hasRole(role: string): Observable<boolean>{
        return this.http.post<boolean>(this.getLegacyUrl(this.srvPath + "/hasRole"), role).pipe(
            catchError(this._serverError));
    }

    isAuthenticated(): Observable<boolean>{      
        return this.http.get<boolean>(this.getLegacyUrl(this.srvPath + "/isAuthenticated")).pipe(
            catchError(this._serverError));        
    }

    
    public set usuarioLogado(usuario : any) {
        this._usuarioLogado = usuario;
    }

    
    public get usuarioLogado() : any {
        return this._usuarioLogado;
    }
    
}