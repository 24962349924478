import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cargo } from 'app/model/cargo';
import { AppConfig } from '../../app.config';
import { GenericService } from './generic.service';
import { PJE_LEGACY } from './rest-servers';

@Injectable()
export class CargoService extends GenericService<Cargo>{

    constructor(private http: HttpClient, private config: AppConfig) { 
        super(http, config);
        this.setRestPath = PJE_LEGACY + '/painelUsuario/cargos';
    }

}