
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';
import { AppConfig } from '../../app.config';
import { PJE_LEGACY } from './rest-servers';
import { Prisao, Soltura, Fuga } from '../../criminal/model';

@Injectable()
export class InformacoesCriminaisService extends GenericService<any>{
    private srvPath: string = PJE_LEGACY + '/informacoes-criminais';

    constructor(private http: HttpClient, config: AppConfig) { 
        super(http, config);
    }

    getInformacoesCriminaisParte(idProcesso : number, idProcessoParte : number) : Observable<any>{
        return this.http.get<any>(this.getLegacyUrl(this.srvPath + '/rascunhos/processo/' + idProcesso + '/' + idProcessoParte)).pipe(
            catchError(this._serverError));
    }

    postIncidenciaPenalParte(idProcesso : number, idProcessoParte : number, incidenciaPenal : any) : Observable<any>{
        return this.http.post(this.getLegacyUrl(this.srvPath + '/rascunhos/processo/' + idProcesso + '/' + idProcessoParte + '/incidencias-penais'), incidenciaPenal);
    }

    getIncidenciaPenalParte(idProcesso : number, idProcessoParte : number) : Observable<any>{
        return this.http.get(this.getLegacyUrl(this.srvPath + '/rascunhos/processo/' + idProcesso + '/' + idProcessoParte + '/incidencias-penais'));
    }

    deleteIncidenciaPenalParte(idProcesso : number, idProcessoParte : number, incidenciaPenal : any) : Observable<any>{
        return this.http.post(
            this.getLegacyUrl(this.srvPath + '/rascunhos/processo/' + idProcesso + '/' + idProcessoParte + '/incidencias-penais/delete'), 
            incidenciaPenal);
    }

    postPrisaoParte(idProcesso : number, idProcessoParte : number, prisao : Prisao) : Observable<any>{
        return this.http.post(this.getLegacyUrl(this.srvPath + '/rascunhos/processo/' + idProcesso + '/' + idProcessoParte + '/prisoes'), prisao);
    } 
    
    getPrisoesParte(idProcesso : number, idProcessoParte : number) : Observable<Prisao[]>{
        return this.http.get<Prisao[]>(this.getLegacyUrl(this.srvPath + '/rascunhos/processo/' + idProcesso + '/' + idProcessoParte + '/prisoes')).pipe(
            catchError(this._serverError));        
    }

    deletePrisaoParte(idProcesso : number, idProcessoParte : number, prisao : Prisao) : Observable<any>{
        return this.http.post(
            this.getLegacyUrl(this.srvPath + '/rascunhos/processo/' + idProcesso + '/' + idProcessoParte + '/prisoes/delete'), 
            prisao);
    }

    postSolturaParte(idProcesso : number, idProcessoParte : number, soltura : Soltura) : Observable<any>{
        return this.http.post(this.getLegacyUrl(this.srvPath + '/rascunhos/processo/' + idProcesso + '/' + idProcessoParte + '/solturas'), soltura);
    } 
    
    getSolturasParte(idProcesso : number, idProcessoParte : number) : Observable<Soltura[]>{
        return this.http.get<Soltura[]>(this.getLegacyUrl(this.srvPath + '/rascunhos/processo/' + idProcesso + '/' + idProcessoParte + '/solturas')).pipe(
            catchError(this._serverError));        
    }

    deleteSolturaParte(idProcesso : number, idProcessoParte : number, soltura : Soltura) : Observable<any>{
        return this.http.post(
            this.getLegacyUrl(this.srvPath + '/rascunhos/processo/' + idProcesso + '/' + idProcessoParte + '/solturas/delete'), 
            soltura);
    }    

    postFugaParte(idProcesso : number, idProcessoParte : number, fuga : Fuga) : Observable<any>{
        return this.http.post(this.getLegacyUrl(this.srvPath + '/rascunhos/processo/' + idProcesso + '/' + idProcessoParte + '/fugas'), fuga);
    } 
    
    getFugasParte(idProcesso : number, idProcessoParte : number) : Observable<Fuga[]>{
        return this.http.get<Fuga[]>(this.getLegacyUrl(this.srvPath + '/rascunhos/processo/' + idProcesso + '/' + idProcessoParte + '/fugas')).pipe(
            catchError(this._serverError));        
    }

    deleteFugaParte(idProcesso : number, idProcessoParte : number, fuga : Fuga) : Observable<any>{
        return this.http.post(
            this.getLegacyUrl(this.srvPath + '/rascunhos/processo/' + idProcesso + '/' + idProcessoParte + '/fugas/delete'), 
            fuga);
    }    
 
}