
import {of as observableOf,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router }      from '@angular/router';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable()
export class AuthenticationGuard extends KeycloakAuthGuard /*implements CanActivate*/ {
    // constructor(private authenticationService: AuthenticationService, private router: Router) { 

    // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        
    //     return this.authenticationService.isAuthenticated().pipe(catchError(
    //         (err) => {
    //             this.router.navigate(['/forbidden-page']);
    //             return observableOf([]);
    //         }));
    // }


    constructor(protected router: Router, protected keycloakAngular: KeycloakService) {
        super(router, keycloakAngular);
    }

    isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this.authenticated) {
                resolve(true);
            } else {
                this.router.navigate(['/forbidden-page']);
                resolve(true);
            }
        });
    }
}