import { NgModule } from '@angular/core';

import { LoggerService } from './services/logger.service';
import { AuthenticationService } from './services/authentication.service';
import { ProcessoService } from './services/processo.service';
import { TarefaService } from './services/tarefa.service';
import { EtiquetaService } from './services/etiqueta.service';
import { UtilService } from './services/util.service';
import { OrgaoJulgadorService } from './services/orgao-julgador.service';
import { PrioridadeProcessoService } from './services/prioridade-processo.service';
import { SessaoService } from './services/sessao.service';
import { PjeOfficeService } from './services/pje-office.service';
import { NotificationsService } from 'angular2-notifications';
import { InformacoesCriminaisService } from './services/informacoes-criminais.service';
import { CriminalService } from "./services/criminal.service";
import { ContestacaoPadraoService } from "./services/contestacao-padrao.service";
import { AuthenticationGuard } from './guards/authentication.guard';
import { ProcessoJudicialService } from './services/processo-judicial.service';
import { CadastroPartesService } from "./services/cadastro-partes.service";
import { ParametrosService } from "./services/parametros.service";
import { AudienciaService } from "./services/audiencia.service";
import { PericiaService } from "./services/pericia.service";
import { CargoService } from './services/cargo.service';
import { InssService } from './services/inss.service';
import { CepService } from "./services/cep.service";
import { PessoaFisicaService } from "../requisitorio/services/pessoa-fisica.service";
import { PessoaJuridicaService } from "../requisitorio/services/pessoa-juridica.service";
import { OrgaoJulgadorColegiadoService } from './services/orgao-julgador-colegiado.service';
import { TipoProcessoDocumentoService } from "./services/tipo-processo-documento.service";

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        LoggerService,
        AuthenticationService,
        UtilService,
        ProcessoService,
        TarefaService,
        EtiquetaService,
        OrgaoJulgadorService,
        PrioridadeProcessoService,
        SessaoService,
        NotificationsService,
        PjeOfficeService,
        AuthenticationGuard,
        InformacoesCriminaisService,
        CriminalService,
        ContestacaoPadraoService,
        ProcessoJudicialService,
        CadastroPartesService,
        ParametrosService,
        AudienciaService,
        PericiaService,
        CargoService,
        InssService,
        CepService,
        PessoaFisicaService,
        PessoaJuridicaService,
		OrgaoJulgadorColegiadoService,
		TipoProcessoDocumentoService
    ],
})
export class CoreModule { }