export enum GenericMessages {
  REGISTRO_SALVO_COM_SUCESSO = 'Registro salvo com sucesso.',
  REGISTRO_ALTERADO_COM_SUCESSO = 'Registro alterado com sucesso.',
  REGISTRO_INATIVADO_COM_SUCESSO = 'Registro inativado com sucesso.',
  REGISTRO_REMOVIDO_COM_SUCESSO = 'Registro removido com sucesso.',
  CAMPOS_OBRIGATORIOS = 'Campos obrigatórios',
  ERRO_RECUPERAR_DADOS = 'Ocorreu um erro ao tentar recuperar os dados',
  ERRO_GRAVAR_REGISTRO = 'Erro ao gravar registro.',
  ERRO_RECUPERAR_DADOS_USUARIO_LOGADO = 'Não foi possível recuperar as informações do usuário logado.',
  EMPTY = '',
}