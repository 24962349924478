import { Directive, ElementRef, Input, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[pjeAutofocus]',
})
export class PjeAutofocusDirective {

    private focus = true;

    constructor(private el : ElementRef){

    }

    ngOnInit() : void {
        if(this.focus){
            window.setTimeout(() =>{
                this.el.nativeElement.focus();
            });
        }                
    }

    @Input()
    set autofocus(condition : boolean){
        this.focus = condition !== false;
    }
}