import { NgModule } from '@angular/core';
import { PjeAutofocusDirective } from './pje-autofocus/pje-autofocus.directive';
import { PjeMascaraCpfCnpjDirective } from './pje-mascara-cpf-cnpj/pje-mascara-cpf-cnpj.directive';
import { PjeDecorateRequiredLabel } from './pje-decorate-required-label/pje-decorate-required-label.directive';

@NgModule({
    exports: [
        PjeMascaraCpfCnpjDirective,
        PjeAutofocusDirective,
        PjeDecorateRequiredLabel      
    ],
    declarations: [
        PjeMascaraCpfCnpjDirective,
        PjeAutofocusDirective,
        PjeDecorateRequiredLabel
    ]
})
export class PjeDirectivesModule { }
