import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { PJE_LEGACY } from './rest-servers';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'app/app.config';
import { Observable } from 'rxjs';
import { PjeResponse } from 'app/model';
import { Cep } from 'app/model/cep.model';

@Injectable()
export class CepService extends GenericService<any>{

    private srvPath: String = PJE_LEGACY + '/api/v1/ceps';

    constructor(private http: HttpClient, private config:AppConfig) {
        super(http, config);
    }

    getCep(codigoCep : String): Observable<PjeResponse<Cep>>{
        if (codigoCep === null || codigoCep === undefined) {
            throw new Error('Não foi possível consultar o CEP, nenhum código foi informado.');
        }
        return this.http.get<PjeResponse<Cep>>(this.getLegacyUrl(this.srvPath + '/' + codigoCep));
    }
    
}