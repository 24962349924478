import { Component, OnInit, Input } from '@angular/core';
import { UtilService, ProcessoService, NotificationMessageService } from '../../../core/services';

@Component({
	selector: 'pje-lembretes',
	templateUrl: './pje-lembretes.component.html',
	styleUrls: ['./pje-lembretes.component.scss']
})
export class PjeLembretesComponent implements OnInit {

	@Input('lembretes')
	public lembretes? : any[];

	@Input('idProcesso')
	public idProcesso : number;

	public showLembretes : boolean = false;

	constructor(private utilService : UtilService, private processoService : ProcessoService, private notificationMessageService: NotificationMessageService) { }

	ngOnInit() {

	}

	abrirCriarLembrete() {
		window.open(this.utilService.webRoot() + '/Processo/lembretes.seam?idProcessoTrf=' + this.idProcesso);
	}

	abrirEditarLembrete(idLembrete : number) {
		window.open(this.utilService.webRoot() + '/Processo/lembretes.seam?idLembrete=' + idLembrete);
	}

	removerLembrete(idLembrete : number) {
		this.processoService.inativarLembrete(idLembrete)
			.subscribe(() => {
				this.lembretes.forEach(lembrete => {
					if(lembrete.idLembrete === idLembrete) {
						lembrete.ativo = false;
					}
					this.lembretes = this.lembretes.filter((lemb) => lemb.ativo === true);
				});
				this.notificationMessageService.sendSuccess('Lembrete', 'Lembrete inativado');
			},
			err => {
				this.notificationMessageService.sendError('Lembrete', 'Ops! Ocorreu um erro ao inativar o lembrete');
			}
		);
		
	}
	
	toggleShowLembrete() {
		this.showLembretes = !this.showLembretes;
	}

}
