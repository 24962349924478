
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContestacaoPadraoDocumento } from 'app/contestacao-padrao/model/contestacao-padrao-documento.model';
import { ContestacaoPadrao } from 'app/contestacao-padrao/model/contestacao-padrao.model';
import { Reu } from 'app/contestacao-padrao/model/reu.model';
import { AssuntoTrf } from 'app/model/assunto-trf';
import { Jurisdicao } from 'app/model/jurisdicao';
import { Observable } from 'rxjs';
import { AppConfig } from '../../app.config';
import { TarefasPendentes } from '../../model/tarefas-pendentes';
import { GenericService } from './generic.service';
import { PJE_LEGACY } from './rest-servers';

@Injectable()
export class ContestacaoPadraoService extends GenericService<TarefasPendentes> {
    private urlPrefix: string = PJE_LEGACY + '/contestacao-padrao';

    constructor(private http: HttpClient, private config: AppConfig) {
        super(http, config);
    }

    public setUrlPrefix(urlPrefix: string) {
        this.urlPrefix = urlPrefix;
    }

    getJurisdicoes(): Observable<Jurisdicao[]> {
        return this.http.get<Jurisdicao[]>(this.getLegacyUrl(this.urlPrefix + '/jurisdicao'));
    }

    getAssuntos(filtro: string, first = 0): Observable<AssuntoTrf[]> {
        let params = new HttpParams().set('filtro', filtro).set('first', first.toString());
        return this.http.get<AssuntoTrf[]>(this.getLegacyUrl(this.urlPrefix + '/assunto'),
            { params: params });
    }

    getAllAssuntos(): Observable<AssuntoTrf[]> {
        return this.http.get<AssuntoTrf[]>(this.getLegacyUrl(this.urlPrefix + '/assuntos'));
    }

    getReus(filtro: string, first = 0): Observable<Reu[]> {
        let params = new HttpParams().set('filtro', filtro).set('first', first.toString());
        return this.http.get<Reu[]>(this.getLegacyUrl(this.urlPrefix + '/reu'),
            { params: params });
    }

    getDocumentos(): Observable<ContestacaoPadraoDocumento[]> {
        return this.http.get<ContestacaoPadraoDocumento[]>(this.getLegacyUrl(this.urlPrefix + '/documento'));
    }

    getContestacoes(): Observable<ContestacaoPadrao[]> {
        return this.http.get<ContestacaoPadrao[]>(this.getLegacyUrl(this.urlPrefix + '/contestacao'));
    }

    getContestacao(idContestacaoPadrao: number): Observable<ContestacaoPadrao> {
        let params = new HttpParams().set('idContestacaoPadrao', idContestacaoPadrao.toString());
        return this.http.get<ContestacaoPadrao>(this.getLegacyUrl(this.urlPrefix + '/contestacao'),
            { params: params });
    }

    salvar(contestacaoPadrao: ContestacaoPadrao): Observable<ContestacaoPadrao> {
        return this.http.post<ContestacaoPadrao>(this.getLegacyUrl(this.urlPrefix + '/contestacao'), contestacaoPadrao);
    }
}
