import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';
import { CookieService } from './core/services/cookies.service';
import { Observable, fromEvent } from 'rxjs';
import { timeout } from 'rxjs/operators';

export function initConfig(appConfig: AppConfig): Function {
  return () => appConfig.load();
}

export const PJE_PAYLOAD = 'pje-payload';

const messages: Observable<any> = fromEvent(window, 'message');

interface IAnalyticsInfo {
  supersetUrl: string;
  jasperServerUrl: string;
  analyticsServerUrl: string;
  token: string;
}

@Injectable()
export class AppConfig {
  AUX_NG_CLIENT = { NG_CLIENT: true };
  private urlGateway: string;
  private analyticsServer: IAnalyticsInfo;
  private pjePayload: any = {};
  private strCookies: string = '';

  constructor(private cookieService: CookieService) {}

  load(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.urlGateway = environment.urlGateway;
      this.initFrame();
      let subs = messages.pipe(timeout(3000)).subscribe(
        (event) => {
          if (event.data.CONSTANTES) {
            resolve();
          }
        },
        (err) => {
          resolve();
        }
      );
    });
  }

  public initPayloadConstantes(pjePayloadConstantes: any) {
    this.pjePayload.CONSTANTES = pjePayloadConstantes;
  }

  private initFrame() {
    if (window.addEventListener) {
      window.addEventListener('message', this.receiveMessage.bind(this), false);
    } else {
      (window as any).attachEvent('onmessage', this.receiveMessage.bind(this));
    }

    sessionStorage.removeItem('currentUser');

    if (this.getUrlPjelegacy()) {
      window.parent.postMessage(this.AUX_NG_CLIENT, this.getUrlPjelegacy());
    }
  }

  private receiveMessage(event) {
    if (event.data.CONSTANTES) {
      this.pjePayload.CONSTANTES = event.data.CONSTANTES;

      if (this.pjePayload.CONSTANTES.COOKIES) {
        this.strCookies = this.pjePayload.CONSTANTES.COOKIES;
        let cookies = this.strCookies.split(';');
        this.copyCookies(cookies);
      }
    }

    if (event.data.respostaIframeTarefa) {
      window.conteudoTarefaComponent.respostaIframeTarefa(
        event.data.respostaIframeTarefa
      );
    }

    if (event.data.respostaIframeTarefaFechar) {
      window.conteudoTarefaComponent.respostaIframeTarefaFechar();
    }

    if (event.data.respostaPopUpTarefa) {
      window.conteudoTarefaComponent.respostaPopUpTarefa();
    }

    if (event.data.atualizarTarefas) {
		window.processoTarefaComponent.atualizarTarefa();
    }

    if (event.data.respostaLembrete) {
      window.processoTarefaComponent.respostaLembrete(
        event.data.respostaLembrete
      );
    }
  }

  private copyCookies(cookies: string[]) {
    cookies.forEach((cookie: string) => {
      let cookieArray: string[] = cookie.split('=');
      this.cookieService.set(
        this.safeTrim(cookieArray[0]),
        this.safeTrim(cookieArray[1]),
        undefined,
        '/',
        undefined,
        true,
        'None'
      );
    });
  }

  private safeTrim(str : string){
    if(str){
        str = str.trim();
    }
    return str;
}

  public getUrlPjelegacy() {
    let ret: string = '';

    if (!document.referrer.includes(window.location.origin)) {
      let pathArray = document.referrer.split('/');

      if (this.pjePayload.CONSTANTES && this.pjePayload.CONSTANTES.WEB_ROOT) {
        pathArray = this.pjePayload.CONSTANTES.WEB_ROOT.split('/');
      }

      if (pathArray.length > 1) {
        let protocol = location.protocol;
        let host = pathArray[2];
        ret = protocol + '//' + host + this.getContextPathPjeLegacy();
      }

      this.setSessionStorageWithExpiry('pjeLegacyUrl', ret, 5000);
    } else {
      ret = this.getFromSessionStorageWithExpiry('pjeLegacyUrl');
    }

    return ret;
  }

  public get pjeLegacyWebRootFromPayload(): string {
    let ret: string = '';

    if (this.pjePayload && this.pjePayload.CONSTANTES) {
      ret = this.pjePayload.CONSTANTES.WEB_ROOT;
    }
    return ret;
  }

  public get idUsuarioLocalizacao(): string {
    let ret: string = '';

    if (this.pjePayload && this.pjePayload.CONSTANTES) {
      ret = this.pjePayload.CONSTANTES.ID_USUARIO_LOCALIZACAO;
    }
    return ret;
  }

  public get pjeLegacyCloudAppName(): string {
    let ret: string = '';

    if (this.pjePayload && this.pjePayload.CONSTANTES) {
      ret = this.pjePayload.CONSTANTES.PJE_APP_NAME;
    }
    return ret;
  }

  public get tipoEditor(): string {
    let ret: string = '';

    if (this.pjePayload && this.pjePayload.CONSTANTES) {
      ret = this.pjePayload.CONSTANTES.TIPO_EDITOR;
    }
    return ret;
  }

  public get tipoJustica(): string {
    let ret: string = '';

    if (this.pjePayload && this.pjePayload.CONSTANTES) {
      ret = this.pjePayload.CONSTANTES.TIPO_JUSTICA;
    }
    return ret;
  }

  public get pjeCodigoSeguranca(): string {
    let ret: string = '';

    if (this.pjePayload && this.pjePayload.CONSTANTES) {
      ret = this.pjePayload.CONSTANTES.CODIGO_SEGURANCA;
    }
    return ret;
  }

    public get modoTesteCertificado() : boolean {
        let ret : boolean = false;

        if(this.pjePayload && this.pjePayload.CONSTANTES && this.pjePayload.CONSTANTES.MODO_TESTE_CERTIFICADO) {
            ret = this.pjePayload.CONSTANTES.MODO_TESTE_CERTIFICADO;
        }

        return ret;
    }

  public get pjeMobile(): string {
    let ret: string = '';

    if (this.pjePayload && this.pjePayload.CONSTANTES) {
      ret = this.pjePayload.CONSTANTES.MOBILE;
    }
    return ret;
  }

  public get corsEnabled(): boolean {
    let ret: boolean = false;

    if (
      this.pjePayload &&
      this.pjePayload.CONSTANTES &&
      this.pjePayload.CONSTANTES.CORS_ENABLED
    ) {
      ret = this.pjePayload.CONSTANTES.CORS_ENABLED;
    }
    return ret;
  }  

  public get ssoEnabled(): boolean {
    let ret: boolean = false;

    if (
      this.pjePayload &&
      this.pjePayload.CONSTANTES &&
      this.pjePayload.CONSTANTES.SSO_ENABLED
    ) {
      ret = JSON.parse(this.pjePayload.CONSTANTES.SSO_ENABLED);
    }

    return ret;
  }

  public getUrlApiGateway() {
    let ret: string = '';

    if (
      this.pjePayload &&
      this.pjePayload.CONSTANTES &&
      this.pjePayload.CONSTANTES.PJE_GATEWAY_SERVICE
    ) {
      ret = this.pjePayload.CONSTANTES.PJE_GATEWAY_SERVICE;
    } else {
      ret = this.urlGateway;
    }

    return ret;
  }

  public getContextPathPjeLegacy() {
    let ret: string = '';

    if (this.pjePayload && this.pjePayload.CONSTANTES) {
      ret = this.pjePayload.CONSTANTES.PATH;
    }
    return ret;
  }

  public getInstancia(): string {
    let ret: string = '';

    if (this.pjePayload && this.pjePayload.CONSTANTES) {
      ret = this.pjePayload.CONSTANTES.INSTANCIA;
    }
    return ret;
  }
 
  public getUrlPDPJMarketplace() : string {
    let ret : string = "";
    
    if (this.pjePayload && this.pjePayload.CONSTANTES) {
        ret = this.pjePayload.CONSTANTES.PDPJ_MARKETPLACE;
    }
    return ret;
} 

  public get cookies(): string {
    return this.strCookies;
  }

  public get jsessionid(): string {
    return 'jsessionid=' + this.pjePayload.CONSTANTES.JSESSIONID;
  }

  setSessionStorageWithExpiry(key: string, value: any, ttl: number) {
    const now: Date = new Date();

    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    sessionStorage.setItem(key, JSON.stringify(item));
  }

  getFromSessionStorageWithExpiry(key: string) {
    const itemStr: any = sessionStorage.getItem(key);

    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      sessionStorage.removeItem(key);
      return null;
    }
    return item.value;
  }

  public get versaoLegacy(): string {
    let ret: string = '';

    if (this.pjePayload && this.pjePayload.CONSTANTES) {
      ret = this.pjePayload.CONSTANTES.VERSAO_LEGACY;
    }
  
    return ret;
  }

  public getUrlAnalyticsService(): string {
    if (this.getAnalyticsInfo() && this.getAnalyticsInfo().analyticsServerUrl) {
      return this.getAnalyticsInfo().analyticsServerUrl;
    }
    return '';
  }

  public getUrlAnalyticsJasperserver(): string {
    if (this.getAnalyticsInfo() && this.getAnalyticsInfo().jasperServerUrl) {
      return this.getAnalyticsInfo().jasperServerUrl;
    }
    return '';
  }

  public getUrlAnalyticsSuperset(): string {
    if (this.getAnalyticsInfo() && this.getAnalyticsInfo().supersetUrl) {
      return this.getAnalyticsInfo().supersetUrl;
    }
    return '';
  }

  public getHeaderAnalytics(): Map<string, string> {
    return new Map([
      ['Authorization', 'Basic ' + this.getAnalyticsInfo().token],
      ['Access-Control-Allow-Credentials', 'true'],
    ]);
  }

  private getAnalyticsInfo(): IAnalyticsInfo {
    if (!this.analyticsServer) {
      const serverInfo = this.getStorageVar('analytics');
      const superset = this.getStorageVar('superset');
      const jasper = this.getStorageVar('jasper');
      const token = this.getStorageVar('analyticsToken');

      this.analyticsServer = {
        analyticsServerUrl: serverInfo,
        jasperServerUrl: jasper,
        supersetUrl: superset,
        token: token,
      };
    }
    return this.analyticsServer;
  }

  private getStorageVar(label: string): string {
    if (sessionStorage.getItem(label)) {
      return sessionStorage.getItem(label);
    }
    return '';
  }
}
