import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'forbidden-page',
	templateUrl: './forbidden-page.component.html',
	styleUrls: ['./forbidden-page.component.scss']
})
export class ForbiddenPageComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
