
import {catchError,  map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GenericService } from './generic.service';
import { Observable } from 'rxjs'
import { PJE_LEGACY } from './rest-servers';
import { AppConfig } from '../../app.config';
import { LoteProcessosInclusaoSessao } from 'app/sessao-julgamento/model/lote-processos-inclusao-sessao';

@Injectable()
export class SessaoService extends GenericService<any>{
    
    private urlPrefix: String = PJE_LEGACY + '/painelUsuario';

    constructor(private http: HttpClient, private config:AppConfig) { 
        super(http, config);
    } 

    getTiposSessao(): Observable<any[]>{
        return this.http.get<any[]>(this.getLegacyUrl(this.urlPrefix + '/tiposSessao'));
    }
    
    getSessoes(params:any): Observable<any>{
        return this.http.post(
            this.getLegacyUrl(this.urlPrefix + '/sessoesComCriterios')
            , params
            ).pipe(catchError(this._serverError));
    }
    
    getListaSessao(idTarefa: number): Observable<any[]> {
        return this.http.get<any[]>(
            this.getLegacyUrl(this.urlPrefix + '/sessaoPauta/' + idTarefa)
        );
    }

    // incluirProcessoSessao(params: any): Observable<any> {
    //     const options = {
    //         headers: new HttpHeaders({'Content-Type': 'application/json'})
	// 	};
        
    //     return this.http.post(
    //         this.getLegacyUrl(this.urlPrefix + '/sessaoPauta'), JSON.stringify(params), options
    //     ).pipe(catchError(this._serverError));
    // }

    incluirLoteSessao(lote: LoteProcessosInclusaoSessao): Observable<LoteProcessosInclusaoSessao> {

        const options = {

                    headers: new HttpHeaders({'Content-Type': 'application/json'})
        };
       
        return this.http.post<LoteProcessosInclusaoSessao>(this.getLegacyUrl(this.urlPrefix + '/sessaoPauta'), 
                              JSON.stringify(lote), options)
                              .pipe(catchError(this._serverError));

    }
}