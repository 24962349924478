export class Norma {
    constructor(public id?: number,
                public numeroNorma?: string,
                public norma?: string,
                public sigla?: string,
                public inicioVigencia?: Date,
                public fimVigencia?: Date) {}

    public get numeroNomeNorma(): string {
        return `${this.numeroNorma} - ${this.norma}`;
    }
}
