import { Component, OnInit, Input } from '@angular/core';
import { ProcessoService, UtilService } from "../../../core/services";
import { AppConfig } from 'app/app.config';

@Component({
	selector: 'pje-link-autos-digitais',
	templateUrl: './link-autos-digitais.component.html',
	styleUrls: ['./link-autos-digitais.component.scss']
})
export class LinkAutosDigitaisComponent implements OnInit {

	@Input()
	idProcessoTrf: number;
	@Input()
	idTaskInstance: number;

	constructor(private processoService: ProcessoService, private config: AppConfig) { }

	ngOnInit() {

	}

	abrirAutos() {
		this.processoService.carregarChaveProcesso(this.idProcessoTrf).subscribe(
			arg => 
			{
				let chaveAcessoProcesso = arg;
				let link = '/Processo/ConsultaProcesso/Detalhe/listAutosDigitais.seam?idProcesso=' + this.idProcessoTrf + '&ca=' + chaveAcessoProcesso;
				if (this.idTaskInstance != undefined) {
					link += '&idTaskInstance=' + this.idTaskInstance;
				}
				window.open(this.config.pjeLegacyWebRootFromPayload + link);
			}
		);
	}
}