import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs'
import { map } from "rxjs/operators";
import { GenericService } from './generic.service';
import { AppConfig } from '../../app.config';
import { PJE_LEGACY } from './rest-servers';
import { PessoaFisica, ProcessoParte, PessoaDocumentoIdentificacao } from '../../cadastro-processo/model';


@Injectable()
export class CadastroPartesService extends GenericService<any>{

    constructor(private http: HttpClient, private config: AppConfig) {
        super(http, config);
    }

    public get urlPrefix() : string {
        return PJE_LEGACY + '/cadastro-partes'
    }

    getCarregarForumlarioInicial(): Observable<any> {
        return this.http.get<any>(this.getLegacyUrl(this.urlPrefix + "/carregar"));
    }

    getProfissoes(profissao: string): Observable<any[]> {
        return this.http.get<any[]>(this.getLegacyUrl(this.urlPrefix + "/profissoes/" + profissao));
    }

    getPessoaFisicaPorCPF(cpf: string): Observable<any> {
        return this.http.get<any>(this.getLegacyUrl(this.urlPrefix + "/pessoas/cpf/" + cpf));
    }

    getPessoaFisica(id : number): Observable<PessoaFisica> {
        return this.http.get<PessoaFisica>(this.getLegacyUrl(this.urlPrefix + "/pessoas/fisica/" + id));
    }

    postPessoaFisica(pessoaFisica : PessoaFisica) : Observable<PessoaFisica> {
        return this.http.post<PessoaFisica>(this.getLegacyUrl(this.urlPrefix + "/pessoas/fisica"), pessoaFisica);
    }

    getPartes(idProcesso : number) : Observable<any> {
        return this.http.get<any>(this.getLegacyUrl(this.urlPrefix + "/processos/" + idProcesso + "/partes"));
    }

    getPartesPassivo(idProcesso : number) : Observable<any> {
        return this.http.get<any>(this.getLegacyUrl(this.urlPrefix + "/processos/" + idProcesso + "/partes/passivo"));
    }
    
    getPartesPassivoSemAdvogado(idProcesso : number) : Observable<any> {
        return this.http.get<any>(this.getLegacyUrl(this.urlPrefix + "/processos/" + idProcesso + "/partes/passivo-sem-advogado"));
    }

    getPartesPoloAtivo(idProcesso : number) : Observable<ProcessoParte[]> {
        return this.http.get<ProcessoParte[]>(this.getLegacyUrl(this.urlPrefix + "/processos/" + idProcesso + "/partes/ativo"));
    }

    getPartesPoloPassivo(idProcesso : number) : Observable<ProcessoParte[]> {
        return this.http.get<ProcessoParte[]>(this.getLegacyUrl(this.urlPrefix + "/processos/" + idProcesso + "/partes/passivo"));
    }

    getPartesPoloOutros(idProcesso : number) : Observable<ProcessoParte[]> {
        return this.http.get<ProcessoParte[]>(this.getLegacyUrl(this.urlPrefix + "/processos/" + idProcesso + "/partes/outros"));
    } 
    
    getDocumentosIdentificacao(idPessoa : number, page : number, pageSize : number) : Observable<PessoaDocumentoIdentificacao[]> {
        return this.http.get<PessoaDocumentoIdentificacao[]>(this.getLegacyUrl(this.urlPrefix + "/pessoas/" + idPessoa + "/documentos-identificacao?page=" + page + "&pageSize=" + pageSize));
    }

    postProcessoParte(idProcesso : number, processoParte : ProcessoParte) : Observable<ProcessoParte>{
        return this.http.post<ProcessoParte>(this.getLegacyUrl(this.urlPrefix + "/processo-partes/" + idProcesso + "/fisica"), processoParte);
    }

    getTipoPartes(idProcesso : number, participacao : string) : Observable<any[]>{
        return this.http.get<any[]>(this.getLegacyUrl(this.urlPrefix + "/processos/" + idProcesso + "/tipo-partes/" + participacao));
    }

}
