import { PjeResponse } from 'app/model';
import { Pessoa } from './../model/pessoa.model';
import { AppConfig } from '../../app.config';
import { GenericService } from '../../core/services/generic.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PJE_LEGACY } from '../../core/services/rest-servers';

@Injectable()
export class PessoaFisicaService extends GenericService<any> {

  private srvPath: string = PJE_LEGACY + '/api/v1/pessoas-fisicas';

  constructor(private http: HttpClient, private config: AppConfig) {
    super(http, config);    
  }

  recuperarPessoaFisica(numeroDocumento: string): Observable<PjeResponse<Pessoa>> {        
    return this.http.get<PjeResponse<Pessoa>>(this.getLegacyUrl(this.srvPath + '/' + numeroDocumento)).pipe(catchError(this._serverError));
  }

  recuperarPessoasFisicasByFilter(numeroDocumento: string, nome: string): Observable<PjeResponse<Pessoa[]>> {        
    var simpleFilter = JSON.stringify({ 
      "dadosBasicos.numeroDocumentoPrincipal": { "contains": numeroDocumento == null ? "" : numeroDocumento },
      "dadosBasicos.nome": { "contains": nome == null ? "" : nome }  
    });    
    let params = new HttpParams().set("filter", simpleFilter);   
    return this.http.get<PjeResponse<Pessoa[]>>(this.getLegacyUrl(this.srvPath), { params: params }).pipe(catchError(this._serverError));
  }

}