import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { PjeResponse } from 'app/model';
import { Observable } from 'rxjs';
import { AppConfig } from '../../app.config';
import { Etiqueta } from '../../model/etiqueta';
import { Tag } from '../../model/tag';
import { GenericService } from './generic.service';
import { PJE_LEGACY } from './rest-servers';

@Injectable()
export class EtiquetaService extends GenericService<Etiqueta>{

    private urlPrefix: String = PJE_LEGACY + '/painelUsuario';

    constructor(private http: HttpClient, private config:AppConfig) { 
        super(http, config);
    }

    public setUrlPrefix(urlPrefix : string) {
        this.urlPrefix = urlPrefix;
    }    

    getEtiquetasUsuario(params: any): Observable<any>{
        return this.http.post(this.getLegacyUrl(this.urlPrefix + "/processoTags/listarTagsUsuario"), params);
    }

    /**
     * @deprecated Por questões de performance, deve-se utilizar o método incluirProcessosTags(params: any[])
     */
    incluirProcessoTag(params: any): Observable<any>{
        return this.http.post(this.getLegacyUrl(this.urlPrefix + "/processoTags/inserir"), params);
    }

    incluirProcessosTags(params: any[]): Observable<any>{
        return this.http.post(this.getLegacyUrl(this.urlPrefix + "/processoTags/inserir"), params);
    }

    excluirProcessoTag(params: any): Observable<any>{
        return this.http.post(this.getLegacyUrl(this.urlPrefix + "/processoTags/remover"), params);
    }

    removerProcessoTag(idTag: number, idProcesso: number): Observable<any>{
        return this.http.delete(this.getLegacyUrl(this.urlPrefix + "/processoTags/remover/"+idTag+"/"+idProcesso));
    }

    getEtiquetasUsuarioDTO(params: any): Observable<any>{
        return this.http.post(this.getLegacyUrl(this.urlPrefix + "/etiquetas"), params);
    }    

    recuperarEtiquetasDescendentes(idTag: number): Observable<any>{
        return this.http.get(this.getLegacyUrl(this.urlPrefix + "/etiquetas/"+idTag+"/subetiquetas"));
    }

    recuperarEtiquetasAscendentes(idTag: number): Observable<any>{
        return this.http.get(this.getLegacyUrl(this.urlPrefix + "/etiquetas/"+idTag+"/ascendentes"));
    }
    
    recuperarNomeTagCompleto(idTag: number): Observable<any>{
        return this.http.get(this.getLegacyUrl(this.urlPrefix + "/etiquetas/"+idTag+"/nomeTagCompleto"), {responseType: "text"});
    }

    atualizarNomeTagCompleto(idTag: number, nomeTagCompleto: string): Observable<any>{
        return this.http.post(this.getLegacyUrl(this.urlPrefix + "/etiquetas/"+idTag+"/nomeTagCompleto"), nomeTagCompleto);
    }

    recuperarEtiquetasParaAutomacao(): Observable<any> {
        return this.http.get(this.getLegacyUrl(this.urlPrefix + "/etiquetas/automacao/lista"));
    }

    executarAutomacao(idTag: number, idProcesso: number): Observable<any> {
        return this.http.post(this.getLegacyUrl(this.urlPrefix + "/etiquetas/"+idTag+"/automacao-executar"), idProcesso, {responseType: "text"});
    }

    executarAutomacaoListaProcessos(idTag: number, idsProcessos: number[]): Observable<any> {
        return this.http.post(this.getLegacyUrl(this.urlPrefix + "/etiquetas/"+idTag+"/automacao-executar-lista"), idsProcessos);
    }

    recuperarProcessosDaEtiqueta(idTag: number): Observable<any> {
        return this.http.get(this.getLegacyUrl(this.urlPrefix + "/etiquetas/"+idTag+"/processos"));
    }

    getTagEdicao(idTag: number): Observable<Tag>{
        return this.http.get<Tag>(this.getLegacyUrl(this.urlPrefix + "/tagEdicao/"+idTag));
    }

    atualizarTag(tag: Tag) : Observable<any>{
        return this.http.put(this.getLegacyUrl(this.urlPrefix + "/tags"), tag);
    }

    incluirTag(tag: Tag) : Observable<any>{
        return this.http.post(this.getLegacyUrl(this.urlPrefix + "/tags"), tag);
    }
    
    getTodasTags(): Observable<Tag[]>{
        return this.http.get<Tag[]>(this.getLegacyUrl(this.urlPrefix + "/processoTags/todas"));
    }

    excluirTag(tag: Tag): Observable<any>{
        return this.http.delete(this.getLegacyUrl(this.urlPrefix + "/tags/" + tag.id));
    }

    excluirTags(tags: Tag[]): Observable<any>{
        let ids : number[] = [];
        tags.forEach(tag => {
            ids.push(tag.id);
        });
        return this.http.post(this.getLegacyUrl(this.urlPrefix + "/tags/excluir-lote"),ids);
    }

    removerTagSessao (etiqueta: Etiqueta) {
        return this.http.get(this.getLegacyUrl(this.urlPrefix+'/tagSessaoUsuario/remover/'+etiqueta.id));
    }

    adicionarTagSessao (etiqueta: Etiqueta) {
        return this.http.get(this.getLegacyUrl(this.urlPrefix+'/tagSessaoUsuario/adicionar/'+etiqueta.id));
    }

    recuperarEtiquetasFiltro(idFiltro: number) {
        return this.http.get<any>(this.getLegacyUrl(this.urlPrefix+'/filtros/' + idFiltro + '/etiquetas'));
    }

    salvarFiltroTag(filtroTag: any) : Observable<any> {
        return this.http.post(this.getLegacyUrl(this.urlPrefix + "/filtrosTags"), filtroTag);
    }

    excluirFiltroTag(filtroTag: any) : Observable<any> {
        return this.http.post(this.getLegacyUrl(this.urlPrefix + "/filtrosTags/excluir"), filtroTag);
    }

    //FILTROS

    recuperarFiltros(params: any) : Observable<any>{
        return this.http.post(this.getLegacyUrl(this.urlPrefix + "/filtros/listar"), params);
    }

    recuperarFiltrosPorEtiqueta(idEtiqueta: number) : Observable<any>{
        return this.http.get(this.getLegacyUrl(this.urlPrefix + "/etiquetas/"+idEtiqueta+"/filtros"));
    }

    salvarFiltro(params: any) : Observable<any>{        
        return this.http.post(this.getLegacyUrl(this.urlPrefix + "/filtros"), params);        
    }

    excluirFiltro(idFiltro: number) : Observable<any>{
        return this.http.delete(this.getLegacyUrl(this.urlPrefix + '/filtro/excluir/' + idFiltro));
    }

    // Assuntos

    recuperarAssuntos() : Observable<any>{
        return this.http.get(this.getLegacyUrl(this.urlPrefix + "/assuntos"));
    }

    // Classes Judiciais
    recuperarClasses() : Observable<any>{
        return this.http.get(this.getLegacyUrl(this.urlPrefix + "/classesJudiciais"));
    }

    // Prioridades
    recuperarPrioridades() : Observable<any>{
        return this.http.get(this.getLegacyUrl(this.urlPrefix + "/prioridades/recuperar"));
    } 


    // Criterios
    recuperarCriterios(idFiltro: number) : Observable<any>{
        return this.http.get(this.getLegacyUrl(this.urlPrefix + "/filtros/"+idFiltro+"/criterios"));
    }

    salvarCriterio(criterio: any) : Observable<any> {
        return this.http.post(this.getLegacyUrl(this.urlPrefix + "/filtros/" + criterio[0].idFiltro + "/criterios"), criterio);
              
    }

    excluirCriterio(idCriterio: number) : Observable<any> {
        return this.http.delete(this.getLegacyUrl(this.urlPrefix + "/criterios/" + idCriterio));
    }

    // Jurisdicoes
    recuperarJurisdicoes() : Observable<any>{
        return this.http.get(this.getLegacyUrl(this.urlPrefix + "/jurisdicoes"));
    }

    recuperarEstados() : Observable<any>{
        return this.http.get(this.getLegacyUrl(this.urlPrefix + "/estados"));
    }

    recuperarMunicipios(idEstado: number) : Observable<any>{
        return this.http.get(this.getLegacyUrl(this.urlPrefix + "/municipios/"+idEstado));
    }

    recuperarOrgaosJulgadorsColegiados(): Observable<any>{
        return this.http.get(this.getLegacyUrl(this.urlPrefix + "/orgaosJulgadoresColegiado"));
    }
}