import { PessoaLegacy } from './../model/pjelegacy/pessoa-legacy.model';
import { PjeResponse } from 'app/model';
import { Pessoa } from '../model/pessoa.model';
import { AppConfig } from '../../app.config';
import { GenericService } from '../../core/services/generic.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PJE_LEGACY } from '../../core/services/rest-servers';

@Injectable()
export class PessoaJuridicaService extends GenericService<any> {

  private srvPath: string = PJE_LEGACY + '/api/v1/pessoas-juridicas';

  constructor(private http: HttpClient, private config: AppConfig) {
    super(http, config);    
  }

  recuperarPessoaJuridica(numeroDocumento: string): Observable<PjeResponse<PessoaLegacy>> {        
    return this.http.get<PjeResponse<PessoaLegacy>>(this.getLegacyUrl(this.srvPath + '/' + numeroDocumento)).pipe(catchError(this._serverError));
  }

  recuperarPessoasJuridicasByFilter(numeroDocumento: string, nome: string): Observable<PjeResponse<PessoaLegacy[]>> {        
    var simpleFilter = JSON.stringify({ 
      "dadosBasicos.numeroDocumentoPrincipal": { "contains": numeroDocumento == null ? "" : numeroDocumento },
      "dadosBasicos.nome": { "contains": nome == null ? "" : nome }  
    });    
    let params = new HttpParams().set("filter", simpleFilter);   
    return this.http.get<PjeResponse<PessoaLegacy[]>>(this.getLegacyUrl(this.srvPath), { params: params }).pipe(catchError(this._serverError));
  }

  recuperarSecaoJudiciaria(): Observable<PjeResponse<PessoaLegacy>> {        
    return this.http.get<PjeResponse<PessoaLegacy>>(this.getLegacyUrl(this.srvPath + '/secao-judiciaria')).pipe(catchError(this._serverError));
  }

}