import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';

@Component({
	selector: 'pje-tab',
	templateUrl: './pje-tab.component.html',
	styleUrls: ['./pje-tab.component.scss']
})
export class PjeTabComponent implements OnInit {

	@Input('tabTitle') title: string;
	@Input('id') id: string;
	@Input() active = false;
	@Input() rendered : boolean = true;
	@Output() onclick : EventEmitter<any> = new EventEmitter();

	constructor() { }

	ngOnInit() {
	}

	isTabActive() {
		return this.active;
	}

	onClickTab(){
		this.onclick.emit();
	}



}
