import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { UtilService } from '../../core/services';
import { KeycloakService } from 'keycloak-angular';
import { AppConfig } from 'app/app.config';
import { catchError, filter, tap } from 'rxjs/operators';

@Injectable()
export class PjeHttpClientInterceptor implements HttpInterceptor {
    constructor(private config: AppConfig, protected keycloakAngular: KeycloakService, private util: UtilService) { }

    private requisicoesMap: Map<string, Subject<any>> = new Map();
    private requisicoesOptionMap: Map<string, Subject<any>> = new Map();

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let user: any = JSON.parse(sessionStorage.getItem('currentUser'));

        let headers;

        if (!user) {
            user = {};
            user.login = "X";
        }
        
        if (this.keycloakAngular.getKeycloakInstance() && this.keycloakAngular.getKeycloakInstance().token) {

            headers = req.headers
                .set("Authorization", req.headers.has("Authorization") ? req.headers.get("Authorization") : "Bearer " + this.keycloakAngular.getKeycloakInstance().token)
                .set("X-pje-legacy-app", this.config.pjeLegacyCloudAppName)
                .set("X-pje-cookies", this.config.cookies)
                .set("X-pje-usuario-localizacao", this.config.idUsuarioLocalizacao);

        } else {
            
            headers = req.headers
                .set("Authorization", req.headers.has("Authorization") ? req.headers.get("Authorization") : "Basic " + btoa(user.login + ':12345'))
                .set("X-no-sso", "true")
                .set("X-pje-legacy-app", this.config.pjeLegacyCloudAppName)
                .set("X-pje-cookies", this.config.cookies);
                
            if (this.config.idUsuarioLocalizacao != "" && this.config.idUsuarioLocalizacao != undefined) {
                headers = headers.append("X-pje-authorization", "Basic " + btoa(user.login + ':12345'));
                headers = headers.append("X-pje-usuario-localizacao", this.config.idUsuarioLocalizacao);
            }

        }

        if (!(req.body instanceof FormData)) {
            headers = headers.append('Content-Type', 'application/json');
        }

        const withCredentials = true;
        const authReq = req.clone({ headers, withCredentials });
        
        const postBody = req.body ? this.util.sha_256(JSON.stringify(req.body)) : "";
        const keyMap = req.url + req.method + postBody;
        const httpObservable = this.processHttpRequest(req,keyMap);
        
        if(httpObservable) return httpObservable;
        
        if(req.method == 'OPTIONS'){
           this.requisicoesOptionMap.set(keyMap, new Subject<any>());
        }else{
           this.requisicoesMap.set(keyMap, new Subject<any>());
        }
        
        return next.handle(authReq)
                   .pipe(filter(res => res.type === HttpEventType.Response),
                         tap(res => this.processHttpResponse(req,res,keyMap)),
                         catchError((err, caught) => {
                            this.requisicoesMap.delete(keyMap);
                            this.requisicoesOptionMap.delete(keyMap);
                            return throwError(err);
                            }
                         )
                       );
    }

    private processHttpRequest(req: HttpRequest<any>, key: string): Observable<HttpEvent<any>>{
        let subject: Subject<any> = null

        if (req.method == 'OPTIONS' && this.requisicoesOptionMap.has(key)) {
            subject = this.requisicoesOptionMap.get(key);
            return subject.asObservable();
        }else if (this.requisicoesMap.has(key)) {
            subject = this.requisicoesMap.get(key);
            return subject.asObservable();
        }
        return null;
    }

    private processHttpResponse(req: HttpRequest<any>, res: HttpEvent<any>, key: string): void{
        if (req.method == 'OPTIONS'){
            const subject = this.requisicoesOptionMap.get(key);
            subject.next(res);
            subject.complete();
        }else{
            const subject = this.requisicoesMap.get(key);
            subject.next(res);
            subject.complete();
            this.requisicoesMap.delete(key);
        }
    }
}
