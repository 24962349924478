import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[pjeDecorateRequiredLabel]',
})
export class PjeDecorateRequiredLabel { 

    @Input('required') required : boolean;
    
    constructor(private el : ElementRef){
        // console.log('pjeDecorateRequiredLabel Directive');
        // el.nativeElement.style.background='red';
        if(this.required) {
            el.nativeElement.value.concat("*");
        }
    }

}